import React, { useEffect, useState } from "react";
import Logo from "../images/Vector-1.svg";
import CustomButton from "../components/CustomButton";
import CustomTextfield from "../components/CustomTextfield";
import KeyIcon from "@mui/icons-material/Key";
import { InputAdornment } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, useNavigate } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useAuth } from "../Context/AuthContext";
import CustomModel from "../components/CustomModel";
import ForgotPassword from "./ForgotPassword";
import { toast } from "react-toastify";

const Signin = () => {
  const {
    newEmail,
    passsword,
    updateNewEmail,
    updatePassword,
    role,
    token,
    username,
    updateUsername,
    updateRole,
    updateToken,
  } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSignupHandler = () => {
    navigate("/signup");
  };
  // const [open, setOpen] = React.useState(false);
  // const onSignindHandler = () => {
  //   setOpen(true);
  // };
  const onLoginHandler = async () => {
    const data = await fetch("https://api.sportzcube.com/api/login", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    });
    // console.log("ttttttt", await data.text());

    // const jsonData = ;

    //   const d = await data.json();
    //   console.log("ttttttt1", d);
    // };

    const responseData = await data.json();
    if (data.ok) {
      console.log(data.ok);
      toast.success(responseData.message);
      updateNewEmail(email);
      updateRole(responseData?.role);
      updateToken(responseData?.token);
      updateUsername(responseData?.username);
      // Navigate based on user role
      if (responseData.role === "user") {
        navigate("/");
      } else if (responseData.role === "admin") {
        navigate("/grids");
      }
    } else toast.error(responseData.message);

    console.log("Registration response:", responseData);
  };

  const onForgotPassworddHandler = () => {
    navigate("/forgotpassword");
  };

  useEffect(() => {
    // console.log("aaaaaa");
    console.log(newEmail);
  }, [newEmail]);

  return (
    <>
      {/* <CustomModel
        buttonHide={false}
        open={open}
        variant={"text"}
        setOpen={setOpen}
        // innerText={innerText}
        termconditionText={termconditionText}
        headingText={"How to play SPORTZCUBE"}
      /> */}
      <div
        className="flex justify-center items-center  h-screen"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <div className=" p-11 rounded-lg shadow-md  bg-max-w-xs w-full lg:w-1/2">
          <div className="items-center flex justify-center">
            <img src={Logo} alt="" />
          </div>
          <h6 className="text-xl font-semibold mb-0  text-white text-center">
            Welcome to <span className="text-[#4775FF]"> SportzCube</span>
          </h6>
          <span className="text-white text-xs mt-0 flex items-center justify-center h-full text-center">
            Enter login credentials to continue
          </span>

          <form>
            <div className="mb-4 mt-11 ">
              <CustomTextfield
                type={"email"}
                size={"small"}
                variant={"filled"}
                placeholder={"Email"}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={setEmail}
              />
            </div>

            <div className="mb-0 mt-4 ">
              <CustomTextfield
                type={"password"}
                size={"small"}
                variant={"filled"}
                placeholder={"Password Here"}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={setPassword}
              />
            </div>
            <div className="flex items-center justify-between mb-7 mt-1">
              <div className="flex items-center mt-0">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Remember me"
                    style={{ color: "white" }}
                  />
                </FormGroup>
              </div>
              <div>
                <div
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                  className="text-md mt-0 text-red-500 hover:underline cursor-pointer"
                >
                  Forgot password?
                </div>
              </div>
            </div>
            <div className="mb-3">
              <CustomButton
                color={"primary"}
                value={"Login"}
                variant={"contained"}
                onClick={onLoginHandler}
              />
            </div>
            <CustomButton
              color={"white"}
              value={"Register"}
              onClick={onSignupHandler}
              variant={"outlined"}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Signin;
