import React, { useEffect, useState } from "react";
import ADrawer from "../components/ADrawer";
import Evaluateadmin from "../components/Evaluateadmin";
import { useAuth } from "../Context/AuthContext";
import { useLocation } from "react-router-dom";

export default function EvaluateScore() {
  const { token } = useAuth();

  const location = useLocation();
  const val = location?.state?.gameId;
  // console.log("val", val);

  const [playerId1, setPlayerId1] = useState("");
  const [fpts1, setFpts1] = useState("");
  const [rank1, setRank1] = useState("");

  const [playerId2, setPlayerId2] = useState("");
  const [fpts2, setFpts2] = useState("");
  const [rank2, setRank2] = useState("");

  const [playerId3, setPlayerId3] = useState("");
  const [fpts3, setFpts3] = useState("");
  const [rank3, setRank3] = useState("");

  const [playerId4, setPlayerId4] = useState("");
  const [fpts4, setFpts4] = useState("");
  const [rank4, setRank4] = useState("");

  const [playerId5, setPlayerId5] = useState("");
  const [fpts5, setFpts5] = useState("");
  const [rank5, setRank5] = useState("");

  const [playerId6, setPlayerId6] = useState("");
  const [fpts6, setFpts6] = useState("");
  const [rank6, setRank6] = useState("");

  const [playerId7, setPlayerId7] = useState("");
  const [fpts7, setFpts7] = useState("");
  const [rank7, setRank7] = useState("");

  const [playerId8, setPlayerId8] = useState("");
  const [fpts8, setFpts8] = useState("");
  const [rank8, setRank8] = useState("");

  const [playerId9, setPlayerId9] = useState("");
  const [fpts9, setFpts9] = useState("");
  const [rank9, setRank9] = useState("");

  const [playerId10, setPlayerId10] = useState("");
  const [fpts10, setFpts10] = useState("");
  const [rank10, setRank10] = useState("");

  const [playerId11, setPlayerId11] = useState("");
  const [fpts11, setFpts11] = useState("");
  const [rank11, setRank11] = useState("");

  const [playerId12, setPlayerId12] = useState("");
  const [fpts12, setFpts12] = useState("");
  const [rank12, setRank12] = useState("");

  const [playerId13, setPlayerId13] = useState("");
  const [fpts13, setFpts13] = useState("");
  const [rank13, setRank13] = useState("");

  const [playerId14, setPlayerId14] = useState("");
  const [fpts14, setFpts14] = useState("");
  const [rank14, setRank14] = useState("");

  const [playerId15, setPlayerId15] = useState("");
  const [fpts15, setFpts15] = useState("");
  const [rank15, setRank15] = useState("");

  const [playerId16, setPlayerId16] = useState("");
  const [fpts16, setFpts16] = useState("");
  const [rank16, setRank16] = useState("");

  const [playerId17, setPlayerId17] = useState("");
  const [fpts17, setFpts17] = useState("");
  const [rank17, setRank17] = useState("");

  const [playerId18, setPlayerId18] = useState("");
  const [fpts18, setFpts18] = useState("");
  const [rank18, setRank18] = useState("");

  const [playerId19, setPlayerId19] = useState("");
  const [fpts19, setFpts19] = useState("");
  const [rank19, setRank19] = useState("");

  const [playerId20, setPlayerId20] = useState("");
  const [fpts20, setFpts20] = useState("");
  const [rank20, setRank20] = useState("");

  const [playerId21, setPlayerId21] = useState("");
  const [fpts21, setFpts21] = useState("");
  const [rank21, setRank21] = useState("");

  const [playerId22, setPlayerId22] = useState("");
  const [fpts22, setFpts22] = useState("");
  const [rank22, setRank22] = useState("");

  const [playerId23, setPlayerId23] = useState("");
  const [fpts23, setFpts23] = useState("");
  const [rank23, setRank23] = useState("");

  const [playerId24, setPlayerId24] = useState("");
  const [fpts24, setFpts24] = useState("");
  const [rank24, setRank24] = useState("");

  //get api
  const [data, setData] = useState([]);
  const listHandler = async () => {
    //  console.log("gameeeeeee");
    const ldata = await fetch(
      "https://api.sportzcube.com/api/getGamePlayersToMark/" + val,
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const lidata = await ldata.json();
    setData(lidata?.game_players);
    setFpts1(lidata?.game_players[0]?.fpts);
    setRank1(lidata?.game_players[0]?.rank);

    setFpts2(lidata?.game_players[1]?.fpts);
    setRank2(lidata?.game_players[1]?.rank);

    setFpts3(lidata?.game_players[2]?.fpts);
    setRank3(lidata?.game_players[2]?.rank);

    setFpts4(lidata?.game_players[3]?.fpts);
    setRank4(lidata?.game_players[3]?.rank);

    setFpts5(lidata?.game_players[4]?.fpts);
    setRank5(lidata?.game_players[4]?.rank);

    setFpts6(lidata?.game_players[5]?.fpts);
    setRank6(lidata?.game_players[5]?.rank);

    setFpts7(lidata?.game_players[6]?.fpts);
    setRank7(lidata?.game_players[6]?.rank);

    setFpts8(lidata?.game_players[7]?.fpts);
    setRank8(lidata?.game_players[7]?.rank);

    setFpts9(lidata?.game_players[8]?.fpts);
    setRank9(lidata?.game_players[8]?.rank);

    setFpts10(lidata?.game_players[9]?.fpts);
    setRank10(lidata?.game_players[9]?.rank);

    setFpts11(lidata?.game_players[10]?.fpts);
    setRank11(lidata?.game_players[10]?.rank);

    setFpts12(lidata?.game_players[11]?.fpts);
    setRank12(lidata?.game_players[11]?.rank);

    setFpts13(lidata?.game_players[12]?.fpts);
    setRank13(lidata?.game_players[12]?.rank);
    setFpts14(lidata?.game_players[13]?.fpts);
    setRank14(lidata?.game_players[13]?.rank);

    setFpts15(lidata?.game_players[14]?.fpts);
    setRank15(lidata?.game_players[14]?.rank);

    setFpts16(lidata?.game_players[15]?.fpts);
    setRank16(lidata?.game_players[15]?.rank);

    setFpts17(lidata?.game_players[16]?.fpts);
    setRank17(lidata?.game_players[16]?.rank);

    setFpts18(lidata?.game_players[17]?.fpts);
    setRank18(lidata?.game_players[17]?.rank);

    setFpts19(lidata?.game_players[18]?.fpts);
    setRank19(lidata?.game_players[18]?.rank);

    setFpts20(lidata?.game_players[19]?.fpts);
    setRank20(lidata?.game_players[19]?.rank);

    setFpts21(lidata?.game_players[20]?.fpts);
    setRank21(lidata?.game_players[20]?.rank);

    setFpts22(lidata?.game_players[21]?.fpts);
    setRank22(lidata?.game_players[21]?.rank);

    setFpts23(lidata?.game_players[22]?.fpts);
    setRank23(lidata?.game_players[22]?.rank);

    setFpts24(lidata?.game_players[23]?.fpts);
    setRank24(lidata?.game_players[23]?.rank);

    console.log("User Games", lidata);
    // console.log("hhhhhhhh", alldata.games);
  };
  // console.log("User fptsss ", fpts1);

  useEffect(() => {
    listHandler();
  }, []);

  return (
    <>
      <div className="text-white flex items-center min-h-[100vh]  w-full">
        <ADrawer />
        <Evaluateadmin
          data={data}
          fpts1={fpts1}
          setFpts1={setFpts1}
          rank1={rank1}
          setRank1={setRank1}
          fpts2={fpts2}
          setFpts2={setFpts2}
          rank2={rank2}
          setRank2={setRank2}
          fpts3={fpts3}
          setFpts3={setFpts3}
          rank3={rank3}
          setRank3={setRank3}
          fpts4={fpts4}
          setFpts4={setFpts4}
          rank4={rank4}
          setRank4={setRank4}
          fpts5={fpts5}
          setFpts5={setFpts5}
          rank5={rank5}
          setRank5={setRank5}
          fpts6={fpts6}
          setFpts6={setFpts6}
          rank6={rank6}
          setRank6={setRank6}
          fpts7={fpts7}
          setFpts7={setFpts7}
          rank7={rank7}
          setRank7={setRank7}
          fpts8={fpts8}
          setFpts8={setFpts8}
          rank8={rank8}
          setRank8={setRank8}
          fpts9={fpts9}
          setFpts9={setFpts9}
          rank9={rank9}
          setRank9={setRank9}
          fpts10={fpts10}
          setFpts10={setFpts10}
          rank10={rank10}
          setRank10={setRank10}
          fpts11={fpts11}
          setFpts11={setFpts11}
          rank11={rank11}
          setRank11={setRank11}
          fpts12={fpts12}
          setFpts12={setFpts12}
          rank12={rank12}
          setRank12={setRank12}
          fpts13={fpts13}
          setFpts13={setFpts13}
          rank13={rank13}
          setRank13={setRank13}
          fpts14={fpts14}
          setFpts14={setFpts14}
          rank14={rank14}
          setRank14={setRank14}
          fpts15={fpts15}
          setFpts15={setFpts15}
          rank15={rank15}
          setRank15={setRank15}
          fpts16={fpts16}
          setFpts16={setFpts16}
          rank16={rank16}
          setRank16={setRank16}
          fpts17={fpts17}
          setFpts17={setFpts17}
          rank17={rank17}
          setRank17={setRank17}
          fpts18={fpts18}
          setFpts18={setFpts18}
          rank18={rank18}
          setRank18={setRank18}
          fpts19={fpts19}
          setFpts19={setFpts19}
          rank19={rank19}
          setRank19={setRank19}
          fpts20={fpts20}
          setFpts20={setFpts20}
          rank20={rank20}
          setRank20={setRank20}
          fpts21={fpts21}
          setFpts21={setFpts21}
          rank21={rank21}
          setRank21={setRank21}
          fpts22={fpts22}
          setFpts22={setFpts22}
          rank22={rank22}
          setRank22={setRank22}
          fpts23={fpts23}
          setFpts23={setFpts23}
          rank23={rank23}
          setRank23={setRank23}
          fpts24={fpts24}
          setFpts24={setFpts24}
          rank24={rank24}
          setRank24={setRank24}
        />
      </div>
    </>
  );
}
