import React from "react";
import ADrawer from "../components/ADrawer";
import DashBoard from "../components/DashBoard";
import TeamDetails from "../components/TeamDetails";
import BasicTable from "../components/BasicTable";

export default function Adminnn() {
  return (
    <>
      <div className="text-white flex items-center min-h-[100vh] w-full ">
        <ADrawer />
        <DashBoard variant={"grids"} />
      </div>
    </>
  );
}
