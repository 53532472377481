import React from 'react'
import ADrawer from '../components/ADrawer';
import DashBoard from '../components/DashBoard';

export default function Settings() {
  return (
    <>
      <div className="text-white flex items-center h-screen w-full">
        <ADrawer />
        <DashBoard variant={"settings"} />
      </div>
    </>
  );
}
