import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, color: "white" }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="text-white" sx={{ width: "100%", color: "white" }}>
      <div
        className="text-white"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ color: "white" }}
          style={{ color: "white" }}
        >
          <Tab
            label="General"
            {...a11yProps(0)}
            sx={{ color: "white" }}
            style={{ color: "white" }}
          />
          <Tab
            label="NFL"
            {...a11yProps(4)}     
            sx={{ color: "white" }}
            style={{ color: "white" }}
          />
          <Tab
            label="NBA"
            {...a11yProps(3)}
            sx={{ color: "white" }}
            style={{ color: "white" }}
          />
          <Tab
            label="MLB"
            {...a11yProps(2)}
            sx={{ color: "white" }}
            style={{ color: "white" }}
          />
          <Tab
            label="NHL"
            {...a11yProps(1)}
            sx={{ color: "white" }}
            style={{ color: "white" }}
          />
        </Tabs>
      </div>
      <CustomTabPanel value={value} index={0}>
        <div>
          {/* <div className="font-bold">Forwards/Wings/Defensemena</div> */}
          {/* <hr /> */}
          <div className="text-xl align-baseline">
            <ol style={{ fontSize: "1.1rem" }}>
              {" "}
              
              <li className="mt-2 mb-4 text-justify">
                • The goal is to pick the highest scoring fantasy player for
                each Positional Square.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                • Fantasy points will be awarded according to the rules set
                forth.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                o In the event of 2 or more players achieving the same fantasy
                point value, Position square rank will be dictated by random
                selection.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                • There is a new SportzCube every day as dictated by number of
                available sporting events being played that day. If not enough
                games are scheduled, there will not be a SportzCube that day.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                o SportzCubes can be comprised of either: Baseball, Football,
                Basketball, or Hockey games yet to be played.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                • Players may be active or inactive although every effort will
                be made to choose active players.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                • Once all the games have been completed, each SportzCube will
                be scored for efficiency and rank according to the following
                formulas.{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                o Cube = (Square+Efficiency)/2{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                Square=sum of achieved position square ranks/6{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify"> Position square rank </li>{" "}
              <li className="mt-2 mb-4 text-justify"> 1=100pts </li>{" "}
              <li className="mt-2 mb-4 text-justify"> 2=75pts </li>{" "}
              <li className="mt-2 mb-4 text-justify"> 3=50pts </li>{" "}
              <li className="mt-2 mb-4 text-justify">4=25pts </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                Efficiency=sum of achieved fantasy points/maximum achievable
                fantasy points
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                • A Cube score of 100 indicates a Perfect SportzCube. (Top
                fantasy scorer from each of the 6 Postion Squares.){" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                • "Your position on the leaderboard will be dictated by your
                Cumulative Cube Score which is a weighted average.
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                Cumulative Cube Score = (sum of all achieved Cube Scores/number
                of times played) X 80% + (most recent Cube Score) X 20%
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                o Eligibility for the leaderboard occurs after 10 completed
                SportzCubes{" "}
              </li>{" "}
              <li className="mt-2 mb-4 text-justify">
                o In head to head matches, if 2 or more SportzCube participants
                select the same lineup, rank will be dictated by time SportzCube
                was completed(i.e. which was submitted earliest)
              </li>{" "}
              <li className="mt-2 mb-4 text-center"> Good luck!</li>{" "}
            </ol>
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <div>
          <div className="font-bold">Forwards/Wings/Defensemen</div>
          <hr />
          <div className="pt-3">Goals=12pts</div>
          <div>Assists=8pts</div>
          <div>Shots on Goal=1.6pts</div>
          <div>Short Handed Points=+2pts</div>
          <div>Power Play Points=+0.5pts</div>
          <div>Blocked Shots=1.6pts</div>
          <div className="font-bold pt-3">Goalies</div>
          <hr />
          <div className="pt-3">Wins=12pts</div>
          <div>Goals Against=-4pts</div>
          <div>Saves=0.8pts</div>
          <div>Shutouts=8pts</div>
          <div className="pt-3">
            Note: No points are awarded for goals or saves during shootouts.
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div>
          <div className="font-bold">Hitters</div>
          <hr />
          <div className="pt-3">1B=3pts</div>
          <div>2B=6pts</div>
          <div>3B=9pts</div>
          <div>HR=12pts</div>
          <div>RBI=3.5pts</div>
          <div>R=3.2pts</div>
          <div>BB=3pts</div>
          <div>SB=6pts</div>
          <div>HBP=3pts</div>
          <div className="font-bold pt-3">Pitchers</div>
          <hr />
          <div className="pt-3">W=6pts</div>
          <div>Quality Start=4pts</div>
          <div>ER=-3pts</div>
          <div>SO=3pts</div>
          <div>IP=3pts*</div>

          <div className="pt-3">*Fractional scoring per out.</div>
          <div className="pt-3">
            Notes: Quality Start is awarded to a starting pitcher who completes
            at least six innings and permits no more than three earned runs.
          </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div>
          <div className="font-bold">OVERVIEW</div>
          <hr />
          <div className="pt-3">We use fractional points.</div>
          <div>We use negative points.</div>

          <div className="font-bold pt-3">ALL PLAYERS</div>
          <hr />
          <div className="pt-3">Three Point Field Goals: 3 points</div>
          <div>Two Point Field Goals: 2 points</div>
          <div>Free Throws Made: 1 point</div>
          <div>Rebounds: 1.2 points</div>
          <div>Assists: 1.5 points</div>
          <div>Blocked Shots: 2 points</div>
          <div>Steals: 2 points</div>
          <div>Turnovers: -1 points</div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div>
          <div className="font-bold">OVERVIEW</div>
          <hr />
          <div className="pt-3">We use fractional points.</div>
          <div>We use negative points.</div>

          <div className="font-bold pt-3">OFFENSIVE PLAYERS</div>
          <hr />
          <div className="pt-3">Passing Yards: 1 point per 25 yards</div>
          <div>Passing Touchdowns: 4 points</div>
          <div>Passing Interceptions: -2 points</div>
          <div>Rushing Yards: 1 point per 10 yards</div>
          <div>Rushing Touchdowns: 6 points</div>
          <div>Receptions: 1 points (using PPR scoring)</div>
          <div>Receiving Yards: 1 point per 10 yards</div>
          <div>Receiving Touchdowns: 6 points</div>
          <div>2-Point Conversions: 2 points</div>
          <div>Fumbles Lost: -2 points</div>
          <div>Fumble Recovered for a Touchdown: 6 points</div>

          <div className="font-bold pt-3">TEAM DEFENSE / SPECIAL TEAMS</div>
          <hr />
          <div className="pt-3">Sacks: 1 point</div>
          <div>Interceptions: 2 points</div>
          <div>Fumbles Recovered: 2 points</div>
          <div>Safeties: 2 points</div>
          <div>Defensive Touchdowns: 6 points</div>
          <div>Kick and Punt Return Touchdowns: 6 points</div>
          <div>2-Point Conversion Returns: 2 points</div>
          <div>Points Allowed (0): 10 points</div>
          <div>Points Allowed (1-6): 7 points</div>
          <div>Points Allowed (7-13): 4 points</div>
          <div>Points Allowed (14-20): 1 points</div>
          <div>Points Allowed (21-27): 0 points</div>
          <div>Points Allowed (28-34): -1 points</div>
          <div>Points Allowed (35+): -4 points</div>
        </div>
      </CustomTabPanel>
    </div>
  );
}
