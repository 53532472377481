import React from 'react'
import ADrawer from '../components/ADrawer';
import DashBoard from '../components/DashBoard';

export default function UserManagement() {
  return (
    <>
      <div className="text-white flex items-center min-h-[100vh]  w-full">
        <ADrawer />
        <DashBoard variant={"user"} />
      </div>
    </>
  );
}
