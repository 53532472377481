import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAuth } from "../Context/AuthContext";

function createData(id, name, email, dob, djoined, actions) {
  return { id, name, email, dob, djoined, actions };
}

const rows = [
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12, 2000",
    "Dec12, 2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12, 2000",
    "Dec12,2000",
    "-"
  ),
];

export default function UserManagementTable() {
  const { token } = useAuth();

  const [table, setTable] = React.useState([]);
  const tableHandler = async () => {
    const tabledata = await fetch("https://api.sportzcube.com/api/users", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("dataaaaaaaaaa", tabledata);
    const jdata = await tabledata.json();
    console.log("jdata", jdata);
    setTable(jdata?.users);
  };
  console.log("array", table);

  // console.log("iddddddd11", table[0].id);

  React.useEffect(() => {
    tableHandler();
  }, []);
  const getDate = (date) => {
    const newDate = new Date(date);
    // console.log(
    //   "lll",
    //   newDate.getDate() +
    //     " - " +
    //     newDate.getMonth() +
    //     " - " +
    //     newDate.getFullYear()
    // );
    return (
      newDate.getMonth() +
      1 +
      "/" +
      newDate.getDate() +
      "/" +
      newDate.getFullYear()
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ color: "white", bgcolor: "transparent" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ color: "white", background: "#4775FF" }}>
          <TableRow>
            <TableCell style={{ color: "white" }}>ID</TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Username
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Email
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Date of Birth
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Date Joined
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {table.map((item, index) => (
            <TableRow
              key={item.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{ color: "white" }}>
                {item.id}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {item.username}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {item.email}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {item?.date_of_birth && getDate(item?.date_of_birth)}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {item?.date_of_birth && getDate(item?.created_at)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
