import React, { useState, createContext, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [newEmail, setNewEmail] = useState(
    localStorage.getItem("newEmail") || ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [role, setRole] = useState(localStorage.getItem("role") || "");
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  const updateNewEmail = (newEmail) => {
    setNewEmail(newEmail);
    localStorage.setItem("newEmail", newEmail);
  };

  const updatePassword = (newPassword) => {
    setPassword(newPassword);
    localStorage.setItem("password", newPassword); // Added this line
  };

  const updateRole = (newRole) => {
    setRole(newRole);
    localStorage.setItem("role", newRole);
  };

  const updateToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  const updateUsername = (username) => {
    setUsername(username);
    localStorage.setItem("username", username);
  };

  useEffect(() => {
    localStorage.setItem("newEmail", newEmail);
  }, [newEmail]);

  useEffect(() => {
    localStorage.setItem("password", password);
  }, [password]);

  useEffect(() => {
    localStorage.setItem("role", role);
  }, [role]);

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    localStorage.setItem("username", username);
  }, [username]);

  return (
    <AuthContext.Provider
      value={{
        newEmail,
        password,
        role,
        token,
        username,
        updateNewEmail,
        updatePassword,
        updateRole,
        updateToken,
        updateUsername,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
