import React, { useState } from "react";
import CustomButton from "../components/CustomButton";
import CustomTextfield from "../components/CustomTextfield";
import { InputAdornment } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [retype, setRetype] = useState("");
  const onforgotHandler = async () => {
    console.log("aaaaa");
    const postdata = await fetch(
      "https://api.sportzcube.com/api/resetPassword",
      {
        method: "POST",
        body: JSON.stringify({
          code,
          new_password: password,
          retype_password: retype,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }
    );

    const responseData = await postdata.json();
    console.log("fffffffffff", responseData);
    // onForgetPasswordHandler();
    if (postdata.ok) {
      toast.success(responseData.message);
      navigate("/signin");
    } else {
      toast.error(responseData.message);
    }
  };

  return (
    <>
      <div className="flex justify-center items-center bg-[#00072E] h-screen">
        <div className="text-white text-center h-[65vh] text-xl  p-11 bg-[#00072E] bg-max-w-xs w-full lg:w-1/2 ">
          Change your password
          <div className="mb-6 mt-4 ">
            <CustomTextfield
              type={"text"}
              size={"small"}
              variant={"filled"}
              value={code}
              onChange={setCode}
              placeholder={"Enter OTP"}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mb-4 mt-4 ">
            <CustomTextfield
              type={"password"}
              size={"small"}
              variant={"filled"}
              value={password}
              onChange={setPassword}
              placeholder={"New Password here "}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mb-6 mt-4 ">
            <CustomTextfield
              type={"password"}
              size={"small"}
              variant={"filled"}
              value={retype}
              onChange={setRetype}
              placeholder={"Confirm Password"}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mb-3">
            <CustomButton
              color={"primary"}
              value={"Change Password"}
              variant={"contained"}
              onClick={onforgotHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
}
