import { Button, InputAdornment } from "@mui/material";
import React from "react";
import CustomTextfield from "./CustomTextfield";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import photo from "../images/Ellipse 22.png";
import SearchIcon from "@mui/icons-material/Search";
import CustomModel from "./CustomModel";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../Context/AuthContext";
import { toast } from "react-toastify";

// const AddLeagueHandler = () => {
//   navigate("/signup");
// };

export default function Admintop({ variant, leaguesrefresh, showing }) {
  const [open, setOpen] = React.useState(false);
  const AddLeagueHandler = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const { token } = useAuth();
  const termconditionText = () => (
    <>
      <hr />
      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          value={name}
          onChange={setName}
          placeholder={"Enter League Name"}
          style={{ borderRadius: "10px" }}
        />
      </div>

      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          value={abbreviation}
          onChange={setAbbreviation}
          placeholder={"Enter Short form"}
          style={{ borderRadius: "10px" }}
        />
      </div>
      <div className="mt-4 align-baseline">
        <Button
          color="white"
          variant="outlined"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
        >
          Cancel
        </Button>
        <Button
          color="white"
          variant="contained"
          onClick={onCreateHandler}
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
          sx={{ background: "#2196FE" }}
        >
          Create
        </Button>
      </div>
    </>
  );

  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");

  const onCreateHandler = async () => {
    try {
      const data = await fetch("https://api.sportzcube.com/api/createLeague", {
        method: "POST",
        body: JSON.stringify({ name, abbreviation }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("ttttttt", await data.text());

      // const jsonData = ;

      //   const d = await data.json();
      //   console.log("ttttttt1", d);
      // };

      const responseData = await data.json();
      if (data.ok) {
        console.log(data.ok);
        toast.success(responseData.message);
        handleClose();
        leaguesrefresh();
      } else toast.error(responseData.message);
      console.log("Create response:", responseData);
    } catch (err) {
      console.log("ffssfdf", err);
      toast.error("Err: Something went wrong");
    }
  };

  const navigate = useNavigate();

  const onCreateGridHandler = () => {
    navigate("/dashboard");
  };

  const onEvaluateHandler = () => {
    navigate("/scoreboard");
  };

  const buttonStyle = {
    padding: "7px 20px",
    borderRadius: "5px",
    margin: "4px",
  };

  if (variant === "dashboard")
    return (
      <>
        <div className="flex items-center justify-between mt-2 mx-2">
          <div>
            <div className="text-2xl mb-3">Grids</div>
          </div>
          <div className="flex items-center">
            <div className="pr-1 font-bold">
              <img src={photo} alt="" />
            </div>
            <div>
              <div className="text-lg">Mike</div>
              <div className="text-gray-400 text-sm">Administrator</div>
            </div>
          </div>
        </div>
        <div className="text-right mt-7">
          <Button
            color="white"
            variant="contained"
            style={buttonStyle}
            sx={{ background: "#2196FE" }}
            onClick={showing}
          >
            Save
          </Button>
        </div>
        <hr className="my-4" />
      </>
    );
  else if (variant === "grids")
    return (
      <>
        <div className="flex items-center justify-between w-full">
          <div className=" ">
            <div className="text-2xl pl-3"> Grids</div>
          </div>
          <div className=" flex items-center ">
            <div></div>
            <div className=" text-white  ">
              <div className="flex ">
                <div className="flex flex-col  justify-center  ">
                  <div className=" pr-1 font-bold">
                    <img src={photo} alt="" />
                  </div>
                </div>
                <div className="p-2 ">
                  <div className="text-lg">Mike </div>
                  <div className="text-gray-400 text-sm">Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" mt-14 text-right"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            {/* <Button color="white" variant="outlined" style={buttonStyle}>
              Select Date
            </Button>
            <Button color="white" variant="outlined" style={buttonStyle}>
              Start Sports
            </Button> */}
            <Button
              color="white"
              variant="contained"
              onClick={onCreateGridHandler}
              style={buttonStyle}
              sx={{ background: "#2196FE" }}
            >
              Create New
            </Button>
          </div>
        </div>
        <hr className="my-4" />
      </>
    );
  else if (variant === "table")
    return (
      <>
        <div className="flex items-center justify-between w-full">
          <div className=" ">
            <div className="text-2xl pl-3">Creating Grids</div>
          </div>
          <div className=" flex items-center ">
            <div className=" text-white  ">
              <div className="flex ">
                <div className="flex flex-col  justify-center  ">
                  <div className=" pr-1 font-bold">
                    <img src={photo} alt="" />
                  </div>
                </div>
                <div className="p-2 ">
                  <div className="text-lg">Mike </div>
                  <div className="text-gray-400 text-sm">Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" mt-14"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <Button
              color="white"
              variant="contained"
              style={buttonStyle}
              sx={{ background: "#2196FE" }}
            >
              Publish
            </Button>
          </div>
        </div>
        <hr className="my-4" />
      </>
    );
  else if (variant === "user")
    return (
      <>
        <div className="flex items-center justify-between w-full">
          <div className=" ">
            <div className="text-2xl pl-3">User Management</div>
          </div>
          <div className=" flex items-center ">
            <div className=" text-white  ">
              <div className="flex ">
                <div className="flex flex-col  justify-center  ">
                  <div className=" pr-1 font-bold">
                    <img src={photo} alt="" />
                  </div>
                </div>
                <div className="p-2 ">
                  <div className="text-lg">Mike </div>
                  <div className="text-gray-400 text-sm">Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" mt-14"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            {/* <Button color="white" variant="outlined" style={buttonStyle}>
              Select Sports
            </Button> */}
          </div>
        </div>
        <hr className="my-4" />
      </>
    );
  else if (variant === "settings")
    return (
      <>
        <div className="flex items-center justify-between w-full">
          <div className=" ">
            <div className="text-2xl pl-3">Settings</div>
          </div>
          <div className=" flex items-center ">
            <div className=" text-white  ">
              <div className="flex ">
                <div className="flex flex-col  justify-center  ">
                  <div className=" pr-1 font-bold">
                    <img src={photo} alt="" />
                  </div>
                </div>
                <div className="p-2 ">
                  <div className="text-lg">Mike </div>
                  <div className="text-gray-400 text-sm">Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else if (variant === "league")
    return (
      <>
        <CustomModel
          buttonHide={true}
          open={open}
          variant={"league"}
          setOpen={setOpen}
          style={{ width: "80vh" }}
          // innerText={innerText}
          termconditionText={termconditionText}
          headingText={"Create New League"}
        />
        <div className="flex items-center justify-between w-full">
          <div className=" ">
            <div className="text-2xl pl-3">Leagues & Teams</div>
          </div>
          <div className=" flex items-center ">
            <div className=" text-white  ">
              <div className="flex ">
                <div className="flex flex-col  justify-center  ">
                  <div className=" pr-1 font-bold">
                    <img src={photo} alt="" />
                  </div>
                </div>
                <div className="p-2 ">
                  <div className="text-lg">Mike </div>
                  <div className="text-gray-400 text-sm">Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" mt-14"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <Button
              color="white"
              variant="contained"
              style={buttonStyle}
              sx={{ background: "#2196FE" }}
              onClick={AddLeagueHandler}
            >
              Add League
            </Button>
          </div>
        </div>
        <hr className="my-4" />
      </>
    );
}
