import React, { useState } from "react";
import Logo from "../images/Vector-1.svg";
import CustomButton from "../components/CustomButton";
import CustomTextfield from "../components/CustomTextfield";
import { InputAdornment, TextField } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyIcon from "@mui/icons-material/Key";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const Signup = () => {
  const navigate = useNavigate();

  const onSigninHandler = () => {
    navigate("/signin");
  };
  const onSignupHandler = () => {
    navigate("/signup");
  };

  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(dayjs('2022-04-17'));
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  const handleDateChange = (newDate) => {
    console.log('wewewewe', newDate)
    setSelectedDate(newDate);
  };

  console.log("sfdfsfsdfsfsfsd", selectedDate);

  const onRegisterHandler = async () => {
    const data = await fetch("https://api.sportzcube.com/api/register", {
      method: "POST",
      body: JSON.stringify({
        username,
        email,
        date_of_birth: selectedDate,
        password: password,
        password_confirmation: repassword,
        // name: "aaaaa",
      }),
      headers: { "Content-Type": "application/json" },
    });
    // onSigninHandler();
    console.log("data");
    console.log(data);
    const d = await data.json();
    if (data.ok) {
      toast.success(d.message);
      onSigninHandler();
    } else {
      const verr =
        d?.message +
        ": " +
        (d && d.errors && d?.errors?.email
          ? d?.errors?.email
          : d?.errors?.username);
      toast.error(verr);
      // onSignupHandler();
    }
    console.log("ttttttt1", d);
    // if (d.ok) {

    // } else {
    //   toast.error(d.message);
    // }
  };

  return (
    <>
      <div
        className="flex justify-center items-center min-h-screen bg-gradient-to-r "
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <div className="p-11 rounded-lg shadow-md bg-max-w-xs w-full sm:w-auto">
          <div className="items-center flex justify-center">
            <img src={Logo} alt="" />
          </div>
          <h6 className="text-xl font-semibold mb-0 text-white text-center">
            Welcome to <span className="text-[#4775FF]">SportzCube</span>
          </h6>
          <span className="text-white text-xs mt-0 flex items-center justify-center h-full text-center">
            Enter login credentials to continue
          </span>

          <form className="mt-4">
            <div className="mb-4">
              <CustomTextfield
                type={"text"}
                size={"small"}
                variant={"filled"}
                placeholder={"Username"}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                value={username}
                onChange={setUserName}
              />
            </div>

            <div className="mb-4">
              <CustomTextfield
                type={"email"}
                size={"small"}
                variant={"filled"}
                placeholder={"Email"}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
                value={email}
                onChange={setEmail}
              />
            </div>

            <div className="mb-4 bg-white ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="Date of Birth"
                    value={selectedDate}
                    onChange={(newValue) => handleDateChange(newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <div className="mb-4">
              <CustomTextfield
                type={"password"}
                size={"small"}
                variant={"filled"}
                placeholder={"Password Here"}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={setPassword}
              />
            </div>

            <div className="mb-4">
              <CustomTextfield
                type={"password"}
                size={"small"}
                variant={"filled"}
                placeholder={"Re-enter Password"}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <KeyIcon />
                    </InputAdornment>
                  ),
                }}
                value={repassword}
                onChange={setRePassword}
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Remember me"
                    style={{ color: "white" }}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="mb-3">
              <CustomButton
                color={"primary"}
                value={"Register"}
                variant={"contained"}
                onClick={onRegisterHandler}
              />
            </div>
            <CustomButton
              color={"white"}
              value={"Login"}
              onClick={onSigninHandler}
              variant={"outlined"}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;
