import React from "react";
import pic from "../images/Ellipse 19.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";


export default function LeaderBoard({ position, item }) {

  
  
  return (
    <>
      <div className="flex w-full px-5 py-4">
        <div className="flex flex-col  justify-center mr-5">{item?.rank}</div>
        <div className="flex flex-col text-center justify-center  ">
          <div className="border-r-2 pr-2 font-bold">
            <AccountCircleIcon />
          </div>
        </div>
        <div className="w-full">
          <div className="flex   justify-between  pt-3 pb-2">
            <div>
              <h4 className="text-xl pl-2  ">{item?.username} </h4>
            </div>
            <div>
              <h4 className="text-lg">
                {item &&
                  item?.cumulative_cube &&
                  parseFloat(item?.cumulative_cube).toFixed(2)}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}
