import React from "react";
import Button from "@mui/material/Button";

export default function CustomButton({
  value,
  variant,
  color,
  className,
  onClick,
  size,
}) {
  return (
    <div>
      <Button
        // type="submit"
        variant={variant}
        color={color}
        size={size ? size : "medium"}
        fullWidth
        className={`${!size && "h-12"} ${className}`}
        onClick={onClick}
      >
        {value}
      </Button>
    </div>
  );
}
