import React from 'react'
import ADrawer from '../components/ADrawer'
import DashBoard from '../components/DashBoard'

export default function CompleteDashboard() {
  return (
    <>
      
      <div className=''
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <div className="text-white flex items-center min-h-[100vh] w-full">
          <ADrawer />
          <DashBoard variant={"dashboard"} />
        </div>
      </div>
    </>
  );
}
