import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(
  name,
  position,
  team,
  match,
  date,
  fpts,
  rank,
  points,
  action
) {
  return { name, position, team, match, date, fpts, rank, points, action };
}

const rows = [
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
  createData("Mikw Adams", "Goaltender", "SL", "SLvsDA", "4:50 pm", "-", "-"),
];

export default function BasicTable() {

  return (
    <TableContainer
      component={Paper}
      sx={{ color: "white", bgcolor: "transparent" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ color: "white", background: "#4775FF" }}>
          <TableRow>
            <TableCell style={{ color: "white" }}>Player Name</TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Position
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Team
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Match
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              TimeDate
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              FPTS
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Ranks
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{ color: "white" }}>
                {row.name}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {row.position}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {row.team}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {row.match}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {row.date}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {row.fpts}
              </TableCell>
              <TableCell align="right" style={{ color: "white" }}>
                {row.rank}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    );
  
}
