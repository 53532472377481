import React, { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function CustomDropdown({
  name,
  team,
  label,
  type,
  teamss,
  handleSelection,
  setTeamss1,
  variant,
  setMatchId,
}) {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    const selectedValue =
      typeof event.target.value === "string" && event.target.value.includes("*")
        ? event.target.value.split("*")[0]
        : event.target.value;
    // console.log("selected value", selectedValue);

    // console.log("qq1111", event.target);
    setAge(event.target.value);
    teamss && teamss(selectedValue);
    const value = event.target.value;
    const splitValue =
      typeof value === "string" && value.includes("*")
        ? value.split("*")[1]
        : null;
    teamss && setTeamss1 && setTeamss1(splitValue); // Assuming this function in parent component handles the selected value
  };
  useEffect(() => {
    // console.log("age");
    // console.log(age);
    var val = age;
    setMatchId && setMatchId(val.split("-")[0]);
  }, [age]);
  // console.log("teammmmmmssssssss", team);

  return (
    <>
      <FormControl fullWidth style={{ background: "white" }} size={"small"}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        {type === "gameplayers" ? (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
          >
            {variant == "team"
              ? type &&
                team &&
                team.map((item, index) => (
                  <MenuItem key={item} value={item}>
                    {/* {console.log("nbnnnbnnnbnn12", item)} */}
                    {item}
                  </MenuItem>
                ))
              : type &&
                team &&
                team.map((item, index) => (
                  <MenuItem
                    key={index}
                    name={"item?.id"}
                    value={
                      item?.home_team?.id +
                      "-" +
                      item?.home_team?.name +
                      "," +
                      item?.away_team?.id +
                      "-" +
                      item?.away_team?.name +
                      "*" +
                      item?.id
                    }
                  >
                    {/* {console.log("nbnnnbnnnbnn", item)} */}
                    {item?.home_team?.abbreviation +
                      " vs " +
                      item?.away_team?.abbreviation}
                  </MenuItem>
                ))}
          </Select>
        ) : (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
          >
            {type &&
              team &&
              team.map((item, index) => (
                <MenuItem key={item.name} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
    </>
  );
}
