import { Button } from "@mui/material";
import React from "react";

export default function Center({
  style,
  date,
  time,
  team1,
  team2,
  name,
  data,
}) {
  const buttonStyle = {
    padding: "2px 10px",
    borderRadius: "10px",
    backgroundColor: "yellow",
    color: "black",
    font: "bold",
  };
  const getDate = (date) => {
    const newDate = new Date(date);
    // console.log("878", newDate, "oooooo", date);
    // console.log(
    //   "lll",
    //   newDate.getDate() +
    //     " - " +
    //     newDate.getMonth() +
    //     " - " +
    //     newDate.getFullYear()
    // );
    return (
      (newDate.getMonth()+1) +
      "/" +
      newDate.getDate() +
      "/" +
      newDate.getFullYear()
    );
  };

  const getTime = (time) => {
    const newTime = new Date(time);
    if (isNaN(newTime)) {
      throw new Error("Invalid date format");
    }

    let hours = newTime.getHours();
    const minutes = newTime.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutesStr + " " + ampm;
  };

  return (
    <>
      <div className="text-white rounded-lg bg-blue-950 cursor-pointer">
        <div
          className="rounded-lg border-2 p-0 mr-auto text-center justify-center items-center "
          style={style}
        >
          {" "}
          <div className="text-lg rounded-lg bg-blue-700 px-1 mx-4 mt-5 ">
            {" "}
            {data?.match?.start_time && getTime(data?.match?.start_time)}
          </div>
          <div className="text-lg ">
            {" "}
            {/* {console.log(
              "poi",
              data?.match?.start_date,
              getDate(data?.match?.start_date)
            )} */}
            {data?.match?.start_date && getDate(data?.match?.start_date)}
          </div>
          <div className=" text-pretty mt-2 text-lg text-yellow-400 lg:text-2xl md:text-2xl sm:text-2xl">
            {data?.player_name}
          </div>
          <div className="mt-2 text-base md:text-lg lg:text-lg ">
            {data?.team?.name}
          </div>
          <div className=" text-yellow-400 py-4 text-lg md:text-2xl lg:text-2xl">
            {" "}
            {data?.match?.home_team?.abbreviation}{" "}
            <span className="text-white"> vs </span>{" "}
            {data?.match?.away_team?.abbreviation}
          </div>
          <div className="text-center items-center py-2 mb-4">
            <Button variant="contained" style={buttonStyle}>
              {data?.position?.abbreviation}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
