import React, { useEffect, useState } from "react";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../Context/AuthContext";
import CustomModel from "./CustomModel";
import { Button } from "@mui/material";
import CustomTextfield from "./CustomTextfield";
import { toast } from "react-toastify";

export default function League({ leagueId, leaguesrefresh, alldata }) {
  const navigate = useNavigate();

  const onLandingHandler = () => {
    navigate("/leagues&teams", {
      replace: true,
      state: { leagueId: leagueId },
    });
  };
  console.log(leagueId);
  const { token } = useAuth();
  const handleClose = () => setOpen(false);

  const [leagues, setLeagues] = useState([]);

  //  useEffect(() => {
  //    deleteHandler();
  //  }, []); // Run once on component mount

  const deleteHandler = async () => {
    try {
      const response = await fetch(
        "https://api.sportzcube.com/api/deleteLeague/" + leagueId,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Assuming token is defined
          },
        }
      );

      if (response.ok) {
        console.log("League deleted successfully");
        leaguesrefresh();
      } else {
        console.error("Failed to delete league:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting league:", error);
    }
  };

  // edit api

  const editlHandler = async () => {
    const leaguedata = await fetch(
      "https://api.sportzcube.com/api/updateLeague/" + leagueId,
      {
        method: "PUT",
        body: JSON.stringify({ name, abbreviation }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const jdata = await leaguedata.json();
    console.log(jdata);

    if (leaguedata.ok) {
      console.log("All leagues updated successfully");
      toast.success("Edit successfully");
      handleClose();
      // Handle any further logic after successful update
    } else {
      console.error("Failed to update all leagues:", leaguedata.statusText);
      toast.error(leaguedata.message);
    }

    //  setAllLeague(jdata.leagues);
    //  console.log(allLeagues);
  };

  //get api
  const getHandler = async () => {
    const data = await fetch(
      "https://api.sportzcube.com/api/showLeague/" + leagueId,
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const jdata = await data.json();
    console.log(jdata);
    //  setGet(jdata.;
    console.log(get);

    setName(jdata?.league?.name);
    setAbbreviation(jdata?.league?.abbreviation);
  };
  const [get, setGet] = useState([]);
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");

  const [open, setOpen] = React.useState(false);
  const AddLeagueHandler = () => {
    setOpen(true);
    getHandler();
  };

  const termconditionText = () => (
    <>
      <hr />
      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          value={name}
          onChange={setName}
          placeholder={"Enter League Name"}
          style={{ borderRadius: "10px" }}
        />
      </div>

      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          value={abbreviation}
          onChange={setAbbreviation}
          placeholder={"Enter Short form"}
          style={{ borderRadius: "10px" }}
        />
      </div>
      <div className="mt-4 align-baseline">
        <Button
          color="white"
          variant="outlined"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
        >
          Cancel
        </Button>
        <Button
          color="white"
          variant="contained"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
          sx={{ background: "#2196FE" }}
          onClick={editlHandler}
        >
          Save
        </Button>
      </div>
    </>
  );

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={open}
        variant={"league"}
        setOpen={setOpen}
        style={{ width: "80vh" }}
        // innerText={innerText}
        termconditionText={termconditionText}
        headingText={"Edit League"}
      />

      <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D]  px-3 py-4 my-2 ml-4">
        <div
          className="flex items-center cursor-pointer justify-between"
          onClick={onLandingHandler}
        >
          <div className="text-xl"> {alldata?.name}</div>
        </div>
        <div className="flex pt-5 ustify-evenly ">
          <div className="justify-stretch pr-5">
            <div className="flex">
              <div>
                {" "}
                <LabelImportantIcon />
              </div>{" "}
              <div> {alldata?.teams_count} teams</div>
            </div>
          </div>
          <div className="flex justify-between cursor-pointer">
            {" "}
            <div onClick={deleteHandler}>
              <DeleteIcon />
            </div>
            <div className="ml-2" onClick={AddLeagueHandler}>
              <EditIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
