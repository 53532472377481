import React from "react";
import pic from "../images/Ellipse 19.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import dayjs from "dayjs";

export default function HeadtoHeadComponent({ position, variant, row, rows }) {
  console.log("jjjj", rows?.game_player?.match?.home_team?.abbreviation);
  const getDate = (date) => {
    const estDateTime = dayjs.utc(date).tz("America/New_York");
    const formattedDate = estDateTime.format("YYYY-MM-DD");
    const formattedTime = estDateTime.format("h:mm A");
    // const newDate = new Date(estDateTime);
    // console.log(
    //   "lll",
    //   newDate.getDate() +
    //     " - " +
    //     newDate.getMonth() +
    //     " - " +
    //     newDate.getFullYear()
    // );
    return formattedDate;
  };

  const getTime = (time) => {
    const newTime = new Date(time);
    if (isNaN(newTime)) {
      throw new Error("Invalid date format");
    }

    const estDateTime = dayjs.utc(time).tz("America/New_York");
    const formattedDate = estDateTime.format("YYYY-MM-DD");
    const formattedTime = estDateTime.format("h:mm A");
    return formattedTime;
  };

  if (variant == "headtohead")
    return (
      <>
        <div className="flex w-full">
          <div className="flex flex-col text-center justify-center  ">
            <div className="flex justify-between">
              <div className="mr-3">{row && row?.rank}</div>
              <div className="border-r-2 pr-1 font-bold">
                <AccountCircleIcon />
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between px-2 pt-4 pb-4 ">
              <div>
                {" "}
                <div>
                  <h4 className="text-xl">{row && row?.username}</h4>
                </div>
                <div className="flex justify-between">
                  <div>
                    <h4 className="text-base text-gray-400">
                      {" "}
                      {row?.game_registered && getDate(row?.game_registered)}
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-base text-gray-400">
                      - {row?.game_registered && getTime(row?.game_registered)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

  if (variant == "teams")
    return (
      <>
        <div className="flex w-full">
          <div className="flex flex-col text-center justify-center  ">
            <div className="border-r-2 pr-1 font-bold text-lg">
              {rows?.game_player?.team?.abbreviation}
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between px-2 pt-4 pb-4 ">
              <div>
                {" "}
                <div className="flex justify-between ">
                  <div>
                    <h4 className="text-xl">
                      {rows?.game_player?.player_name}
                    </h4>
                  </div>
                  <div className="text-center">
                    ({rows?.game_player?.position?.abbreviation})
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <h4 className="text-base text-gray-400">
                      {rows &&
                        rows?.game_player?.match?.home_team?.abbreviation +
                          " vs " +
                          rows?.game_player?.match?.away_team?.abbreviation}
                    </h4>
                  </div>
                  <div>
                    <h4 className="text-base text-gray-400">
                      {" "}
                      -
                      {rows?.game_player?.match?.start_time &&
                        getTime(rows?.game_player?.match?.start_time)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
