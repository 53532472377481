import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import Routes from "./Routes";
import { AuthProvider } from "./Context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  <AuthProvider>
    <ToastContainer />
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);
