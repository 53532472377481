import React, { useEffect, useState } from "react";
import NavApp from "../components/NavApp";
import CustomTextfield from "../components/CustomTextfield";
import CustomButton from "../components/CustomButton";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import BasicTable from "../components/BasicTable";
import Profile from "../components/Profile";
import LeaderBoard from "../components/LeaderBoard";
import { useAuth } from "../Context/AuthContext";

export default function UleaderBoard() {
  const { token } = useAuth();
  const [searchText, setSearchText] = useState("");

  // main api for user

  const [topScorer, setTopscorer] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const LeaderBoardHandler = async () => {
    //  console.log("gameeeeeee");
    const odata = await fetch(
      "https://api.sportzcube.com/api/leaderboardUsers",
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const alldata = await odata.json();
    setTopscorer(alldata);
    setUsers(alldata?.users);
    console.log("leaderboarddddddd", alldata);

    // console.log("hhhhhhhh", alldata.games);
  };
  // console.log(users);

  useEffect(() => {
    LeaderBoardHandler();
  }, []);

  const [newRows, setNewRows] = React.useState(users);

  React.useEffect(() => {
    const filteredRows = users?.filter((row) =>
      row?.username?.includes(searchText)
    );
    setNewRows(filteredRows);
  }, [searchText, users]);

  var userName = localStorage.getItem("username");

  users?.sort((b, a) => a.cube - b.cube);
  return (
    <>
      <div
        className="pb-4 text-white min-h-[100vh]"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <NavApp />
        <div className="text-white text-2xl pt-4 pl-4 pb-4 mt-3 mb-5">
          {" "}
          LeaderBoard
        </div>
        {/* <div className="text-white text-lg pt-4 pl-4"> Head To Head</div>
        <div className="text-gray-400 text-base pl-4 pb-4">
          Click on current player to view their selected team.
        </div> */}

        <div className="flex justify-between  ml-4 pb-4">
          <div className="w-3/4 block">
            <CustomTextfield
              type={"text"}
              size={"small"}
              variant={"outlined"}
              placeholder={"search"}
              value={searchText}
              onChange={setSearchText}
              style={{ border: "1px solid border green", borderRadius: "20px" }}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        {token && <Profile topScorer={topScorer} variant={"leaderboard"} />}
        <div className="flex justify-between mx-5 mt-3 text-gray-300">
          <div className="flex">
            <div className="mr-5">#</div>
            <div>username</div>
          </div>
          <div>Cumulative Cube</div>
        </div>

        {searchText !== "" &&
          users
            .slice()
            .filter(
              (item) =>
                item?.username === "Jonnie Aces" ||
                item?.username === `${userName}`
            )
            .sort((a, b) => b.average_cube - a.average_cube)
            .map(
              (item, index) =>
                item?.rank && (
                  <LeaderBoard
                    position={index}
                    item={item}
                    searchText={searchText}
                  />
                )
            )}
        {searchText !== "" &&
          newRows
            .filter((item) => item?.username !== `${userName}`)
            .slice()
            .sort((a, b) => b.average_cube - a.average_cube)
            .map(
              (item, index) =>
                item?.rank && (
                  <LeaderBoard
                    position={index + 1}
                    item={item}
                    searchText={searchText}
                  />
                )
            )}
        {searchText == "" &&
          newRows

            .slice()
            .sort((a, b) => b.average_cube - a.average_cube)
            .map(
              (item, index) =>
                item?.rank && (
                  <LeaderBoard
                    position={index + 1}
                    item={item}
                    searchText={searchText}
                  />
                )
            )}
      </div>
    </>
  );
}
