import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

export default function PlayerDetails({ item, game }) {
  const getDate = (date) => {
    const newDate = new Date(date);
    // console.log(
    //   "lll",
    //   newDate.getDate() +
    //     " - " +
    //     newDate.getMonth() +
    //     " - " +
    //     newDate.getFullYear()
    // );
    return (
      newDate.getMonth() +
      1 +
      "/" +
      newDate.getDate() +
      "/" +
      newDate.getFullYear()
    );
  };

const getTime = (time) => {
  const newTime = new Date(time);
  if (isNaN(newTime)) {
    throw new Error("Invalid date format");
  }

  let hours = newTime.getHours();
  const minutes = newTime.getMinutes();
 
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;


  return hours + ":" + minutesStr +  " " + ampm;
};


  return (
    <>
      <div className="flex w-full">
        <div className="flex flex-col text-center justify-center  ">
          <div className="border-r-2 pr-1 font-bold">
            <h3>{item?.team?.abbreviation}</h3>
          </div>
        </div>
        <div className="w-full">
          <div className="flex items-center justify-between px-2 pt-4 pb-4 ">
            <div>
              {" "}
              <div>
                <h4 className="text-xl">{item?.player_name}</h4>
              </div>
              <div>
                <h4 className="text-base text-gray-400">
                  {item?.match?.home_team?.abbreviation +
                    " vs " +
                    item?.match?.away_team?.abbreviation}{" "}
                </h4>
              </div>
            </div>
            <div className=" pl-2 sm:w-2 md:w-48 lg:w-60 xl:w-[160vh]">
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  value={item?.faith}
                  color="yellow"
                  sx={{ border: '1px solid black',  borderRadius:'50%'}}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="white"
                  >
                    {`${Math.round(item?.faith)}%`}
                  </Typography>
                </Box>
              </Box>
            </div>
            <div>
              <div className="text-lg rounded-2xl bg-blue-700 py-0 px-2 ">
                {" "}
                {item?.match?.start_time && getTime(item?.match?.start_time)}
              </div>

              <div className="text-base text-gray-400 ">
                {" "}
                {item?.match?.start_date && getDate(item?.match?.start_date)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
