import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import logo from "../images/Logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomModel from "./CustomModel";

const drawerWidth = 240;

export default function ADrawer() {
  const linksUrl = ["/grids", "/leagues", "/usermanagement", "/settings"];
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("selectedDrawer")
      ? JSON.parse(localStorage.getItem("selectedDrawer"))
      : ""
  );

  const handlePageChange = (index) => {
    localStorage.setItem("selectedDrawer", JSON.stringify(index));
    setSelectedIndex(index);
  };

  const handleLogout = () => {
    // Handle logout logic here
  };
  const [logout, setLogout] = React.useState(false);
  const LogoutHandler = () => {
    setLogout(true);
  };

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={logout}
        variant={"logout"}
        setOpen={setLogout}
      />
      <div
        className="text-white flex"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "#051955",
              color: "white",
            },
          }}
        >
          <div className="pl-4">
            <img src={logo} alt="" />
          </div>

          <Box
            sx={{ overflow: "auto" }}
            style={{ color: "white", marginTop: "20px", flexGrow: 1 }}
          >
            <List>
              {["Grids", "Leagues & Teams", "User Management", "Settings"].map(
                (text, index) => (
                  <ListItem
                    key={text}
                    disablePadding
                    sx={{
                      background:
                        selectedIndex === index ? "#2196FE" : "transparent",
                    }}
                    style={{ paddingLeft: "7px" }}
                  >
                    <ListItemButton
                      onClick={() => {
                        navigate(linksUrl[index]);
                        handlePageChange(index);
                      }}
                    >
                      <ListItemIcon style={{ color: "white", padding: "1px" }}>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </Box>
          {/* Logout button */}
          <ListItem
            button
            onClick={LogoutHandler}
            style={{ marginTop: "auto", color: "white" }}
          >
            <ListItemIcon style={{ color: "white", padding: "1px" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Drawer>
      </div>
    </>
  );
}
