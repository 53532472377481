import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useNavigate } from "react-router-dom";
import Brightness1Icon from "@mui/icons-material/Brightness1";

export default function TeamDetails({style,item}) {

  const navigate = useNavigate();
  const dashboardHandler = (id) => {
    navigate("/scoreboard", {
      replace: true,
      state: { gameId: item?.id},
    });
  };

  const getTime = (time) => {
    // console.log("ooooooo", time);
    const [hours, minutes] = time.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error("Invalid time format");
    }

    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12; // convert 0 to 12 for midnight

    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    return hours12 + ":" + minutesStr + " " + ampm;
  };

  // console.log("rrrrrrrrrrr", item?.id);
  return (
    <>
      <div
        className=" cursor-pointer rounded-lg  text-white bg-[#10368D] px-3 py-4 my-2 ml-4"
        style={style}
        onClick={dashboardHandler}
      >
        <div className="flex items-center justify-between">
          <div className="text-xl"> {item?.league?.name}</div>
          <div className="">
            {item?.marked_players ? (
              <Brightness1Icon />
            ) : (
              <Brightness1Icon style={{ color: "red" }} />
            )}
          </div>
        </div>
        <div className="flex ">
          <div className="pt-6 text-xs">
            {" "}
            <CalendarTodayIcon />
            {item?.cutoff_date}
          </div>
          <div>
            <div className="pt-6 pl-4 text-xs">
              {" "}
              <TimelapseIcon />{" "}
              {item?.cutoff_time && getTime(item?.cutoff_time)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
