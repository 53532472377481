import React, { useEffect, useState } from "react";
import CustomTextfield from "./CustomTextfield";
import { Button, Grid, InputAdornment } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import photo from "../images/Ellipse 22.png";
import SearchIcon from "@mui/icons-material/Search";
import BasicTable from "./BasicTable";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AdminTeamComponent1 from "./AdminTeamComponent1";
import CreateIcon from "@mui/icons-material/Create";
import AdminGoaltender from "./AdminGoaltender";
import Admintop from "./Admintop";
import TeamDetails from "./TeamDetails";
import UserManagement from "../pages/UserManagement";
import UserManagementTable from "./UserManagementTable";
import AdminSettings from "./AdminSettings";
import League from "./League";
import LeagueList from "./LeagueList";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { toast } from "react-toastify";
import RadioGroup from "@mui/material/RadioGroup";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export default function DashBoard({ variant }) {
  dayjs.extend(utc);

  const buttonStyle = {
    padding: "3px 8px",
    borderRadius: "4px",
    margin: "4px",
  };
  const navigate = useNavigate();
  const { token } = useAuth();

  const apiHandler = async () => {
    const data = await fetch("https://api.sportzcube.com/api/games", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      // headers: { "Content-Type": "application/json" },
    });
    // console.log(data);
    const jdata = await data.json();
    console.log(jdata);
    setGames(jdata.games);
    console.log(games);
  };
  const [games, setGames] = useState([]);
  const [gameId, setGameId] = useState("");

  useEffect(() => {
    apiHandler();
    leagueHandler();
    GamesShowHandler();
    getAllHandler();
    getMatchesHandler();
    gameId && getShowGameHandler();
  }, [gameId]);
  // create league api
  const leagueHandler = async () => {
    const data = await fetch("https://api.sportzcube.com/api/leagues", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const jdata = await data.json();
    console.log(jdata);
    setLeague(jdata.leagues);
    console.log(leagues);
  };
  const [leagues, setLeague] = useState([]);

  // get all leagues api

  const [allLeagues, setAllLeague] = useState([]);
  const getAllHandler = async () => {
    const leaguedata = await fetch("https://api.sportzcube.com/api/leagues", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const jdata = await leaguedata.json();
    console.log("oiuyyr", jdata);
    setAllLeague(jdata.leagues);
    console.log(allLeagues);
  };
  // post games api
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [selectedLeague, setSelectedLeague] = useState(null);

  // console.log("oooooooooooooooooo", newPositions?.positions[0]?.position);
  // console.log("oooooddddddddddddddd", newPositions?.positions[0]?.id);
  //main api
  const [newww, setNewww] = useState("");
  const [matchId, setMatchId] = useState("");
  const [newww2, setNewww2] = useState("");
  const [newww3, setNewww3] = useState("");
  const [newww4, setNewww4] = useState("");
  const [newww5, setNewww5] = useState("");
  const [newww6, setNewww6] = useState("");
  const [newww7, setNewww7] = useState("");
  const [newww8, setNewww8] = useState("");
  const [newww9, setNewww9] = useState("");
  const [newww10, setNewww10] = useState("");
  const [newww11, setNewww11] = useState("");
  const [newww12, setNewww12] = useState("");
  const [newww13, setNewww13] = useState("");
  const [newww14, setNewww14] = useState("");
  const [newww15, setNewww15] = useState("");
  const [newww16, setNewww16] = useState("");
  const [newww17, setNewww17] = useState("");
  const [newww18, setNewww18] = useState("");
  const [newww19, setNewww19] = useState("");
  const [newww20, setNewww20] = useState("");
  const [newww21, setNewww21] = useState("");
  const [newww22, setNewww22] = useState("");
  const [newww23, setNewww23] = useState("");
  const [newww24, setNewww24] = useState("");

  const [match1, setMatch1] = useState("");
  const [name1, setName1] = useState("");
  const [position1, setPosition1] = useState("");
  const [teamss1, setTeamss1] = useState("");

  const [match2, setMatch2] = useState("");
  const [name2, setName2] = useState("");
  const [position2, setPosition2] = useState("");
  const [teamss2, setTeamss2] = useState("");

  const [match3, setMatch3] = useState("");
  const [name3, setName3] = useState("");
  const [position3, setPosition3] = useState("");
  const [teamss3, setTeamss3] = useState("");

  const [match4, setMatch4] = useState("");
  const [name4, setName4] = useState("");
  const [position4, setPosition4] = useState("");
  const [teamss4, setTeamss4] = useState("");

  const [match5, setMatch5] = useState("");
  const [name5, setName5] = useState("");
  const [position5, setPosition5] = useState("");
  const [teamss5, setTeamss5] = useState("");

  const [match6, setMatch6] = useState("");
  const [name6, setName6] = useState("");
  const [position6, setPosition6] = useState("");
  const [teamss6, setTeamss6] = useState("");

  const [match7, setMatch7] = useState("");
  const [name7, setName7] = useState("");
  const [position7, setPosition7] = useState("");
  const [teamss7, setTeamss7] = useState("");

  const [match8, setMatch8] = useState("");
  const [name8, setName8] = useState("");
  const [position8, setPosition8] = useState("");
  const [teamss8, setTeamss8] = useState("");

  const [match9, setMatch9] = useState("");
  const [name9, setName9] = useState("");
  const [position9, setPosition9] = useState("");
  const [teamss9, setTeamss9] = useState("");

  const [match10, setMatch10] = useState("");
  const [name10, setName10] = useState("");
  const [position10, setPosition10] = useState("");
  const [teamss10, setTeamss10] = useState("");

  const [match11, setMatch11] = useState("");
  const [name11, setName11] = useState("");
  const [position11, setPosition11] = useState("");
  const [teamss11, setTeamss11] = useState("");

  const [match12, setMatch12] = useState("");
  const [name12, setName12] = useState("");
  const [position12, setPosition12] = useState("");
  const [teamss12, setTeamss12] = useState("");

  const [match13, setMatch13] = useState("");
  const [name13, setName13] = useState("");
  const [position13, setPosition13] = useState("");
  const [teamss13, setTeamss13] = useState("");

  const [match14, setMatch14] = useState("");
  const [name14, setName14] = useState("");
  const [position14, setPosition14] = useState("");
  const [teamss14, setTeamss14] = useState("");

  const [match15, setMatch15] = useState("");
  const [name15, setName15] = useState("");
  const [position15, setPosition15] = useState("");
  const [teamss15, setTeamss15] = useState("");

  const [match16, setMatch16] = useState("");
  const [name16, setName16] = useState("");
  const [position16, setPosition16] = useState("");
  const [teamss16, setTeamss16] = useState("");

  const [match17, setMatch17] = useState("");
  const [name17, setName17] = useState("");
  const [position17, setPosition17] = useState("");
  const [teamss17, setTeamss17] = useState("");

  const [match18, setMatch18] = useState("");
  const [name18, setName18] = useState("");
  const [position18, setPosition18] = useState("");
  const [teamss18, setTeamss18] = useState("");

  const [match19, setMatch19] = useState("");
  const [name19, setName19] = useState("");
  const [position19, setPosition19] = useState("");
  const [teamss19, setTeamss19] = useState("");

  const [match20, setMatch20] = useState("");
  const [name20, setName20] = useState("");
  const [position20, setPosition20] = useState("");
  const [teamss20, setTeamss20] = useState("");

  const [match21, setMatch21] = useState("");
  const [name21, setName21] = useState("");
  const [position21, setPosition21] = useState("");
  const [teamss21, setTeamss21] = useState("");

  const [match22, setMatch22] = useState("");
  const [name22, setName22] = useState("");
  const [position22, setPosition22] = useState("");
  const [teamss22, setTeamss22] = useState("");

  const [match23, setMatch23] = useState("");
  const [name23, setName23] = useState("");
  const [position23, setPosition23] = useState("");
  const [teamss23, setTeamss23] = useState("");

  const [match24, setMatch24] = useState("");
  const [name24, setName24] = useState("");
  const [position24, setPosition24] = useState("");
  const [teamss24, setTeamss24] = useState("");

  const [date, setDate] = useState(dayjs());

  const [time, setTime] = useState(dayjs("2022-04-17T15:30"));
  const [newPositions, setNewPositions] = useState("");
  const [mat, setMat] = useState([]);
  const [respData, setRespData] = useState(null);
  const [leagueId, setLeagueId] = useState("");
  const onCreateGameHandler = async (index) => {
    // try {
    // console.log("item",name);
    // console.log("response",abbreviation);
    // console.log("fghff:", val);
    console.log("llllddddddddd", leagues[index]);
    const postdata = await fetch(
      "https://api.sportzcube.com/api/createGame/" + leagues[index].id,
      {
        method: "POST",
        //  body: JSON.stringify({   }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      }
    );
    // console.log("ttttttt", await data.text());

    // const jsonData = ;

    //   const d = await data.json();
    //   console.log("ttttttt1", d);
    // };
    console.log("res", postdata);
    const responseData = await postdata.json();
    console.log("gggggggggggggggghhhhh", responseData);
    //  console.log("pottttttttttt",postdata.ok);

    if (postdata.ok) {
      console.log("okkkkkkkkk", responseData);
      console.log("mmmmmmm", responseData?.matches);
      setRespData(responseData);
      setGameId(responseData?.game?.id);
      setLeagueId(leagues[index].id);
      setMat(responseData?.matches);

      console.log("gameiddd", responseData?.game?.id);
      toast.success(responseData.message);
    } else {
      console.error(responseData.message);
      console.error(responseData.error);
      toast.error(responseData.message);
    }

    console.log("Team save response:", responseData);
    // } catch (err) {
    //   console.log("lll", err);
    // }
  };

  // get matches api
  console.log("idfgghjkghghft", gameId);

  const [matches, setMatches] = useState([]);
  const getMatchesHandler = async () => {
    if (leagueId && gameId) {
      const matchdata = await fetch(
        "https://api.sportzcube.com/api/matches/" + gameId,
        {
          method: "GET",
          // body: JSON.stringify({ email, password }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(data);
      const mdata = await matchdata.json();
      console.log("hhhhhhhhhhhhhhhhhh", mdata);
    }
  };

  //showgame new api

  const getShowGameHandler = async () => {
    console.log("gameeeeeee1");

    const gdata = await fetch(
      "https://api.sportzcube.com/api/showGame/" + gameId,
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const newdata = await gdata.json();
    setMatches(newdata.matches);
    console.log("updateeeekkkkk", newdata.positions[0].position);
    console.log("gameeeeeeeeeeeee1", newdata);
    setNewPositions(newdata);
  };

  useEffect(() => {
    setPosition1(newPositions && newPositions?.positions[0]?.id);
    setPosition2(newPositions && newPositions?.positions[0]?.id);
    setPosition3(newPositions && newPositions?.positions[0]?.id);
    setPosition4(newPositions && newPositions?.positions[0]?.id);
    setPosition5(newPositions && newPositions?.positions[1]?.id);
    setPosition6(newPositions && newPositions?.positions[1]?.id);
    setPosition7(newPositions && newPositions?.positions[1]?.id);
    setPosition8(newPositions && newPositions?.positions[1]?.id);
    setPosition9(newPositions && newPositions?.positions[2]?.id);
    setPosition10(newPositions && newPositions?.positions[2]?.id);
    setPosition11(newPositions && newPositions?.positions[2]?.id);
    setPosition12(newPositions && newPositions?.positions[2]?.id);
    setPosition13(newPositions && newPositions?.positions[3]?.id);
    setPosition14(newPositions && newPositions?.positions[3]?.id);
    setPosition15(newPositions && newPositions?.positions[3]?.id);
    setPosition16(newPositions && newPositions?.positions[3]?.id);
    setPosition17(newPositions && newPositions?.positions[4]?.id);
    setPosition18(newPositions && newPositions?.positions[4]?.id);
    setPosition19(newPositions && newPositions?.positions[4]?.id);
    setPosition20(newPositions && newPositions?.positions[4]?.id);
    setPosition21(newPositions && newPositions?.positions[5]?.id);
    setPosition22(newPositions && newPositions?.positions[5]?.id);
    setPosition23(newPositions && newPositions?.positions[5]?.id);
    setPosition24(newPositions && newPositions?.positions[5]?.id);

    setNewww(newPositions && newPositions?.matches[0]?.id);
    setNewww2(newPositions && newPositions?.matches[1]?.id);
    setNewww3(newPositions && newPositions?.matches[2]?.id);
    setNewww4(newPositions && newPositions?.matches[3]?.id);
    setNewww5(newPositions && newPositions?.matches[4]?.id);
    setNewww6(newPositions && newPositions?.matches[5]?.id);
    setNewww7(newPositions && newPositions?.matches[6]?.id);
    setNewww8(newPositions && newPositions?.matches[7]?.id);
    setNewww9(newPositions && newPositions?.matches[8]?.id);
    setNewww10(newPositions && newPositions?.matches[9]?.id);
    setNewww11(newPositions && newPositions?.matches[10]?.id);
    setNewww12(newPositions && newPositions?.matches[11]?.id);
    setNewww13(newPositions && newPositions?.matches[12]?.id);
    setNewww14(newPositions && newPositions?.matches[13]?.id);
    setNewww15(newPositions && newPositions?.matches[14]?.id);
    setNewww16(newPositions && newPositions?.matches[15]?.id);
    setNewww17(newPositions && newPositions?.matches[16]?.id);
    setNewww18(newPositions && newPositions?.matches[17]?.id);
    setNewww19(newPositions && newPositions?.matches[18]?.id);
    setNewww20(newPositions && newPositions?.matches[19]?.id);
    setNewww21(newPositions && newPositions?.matches[20]?.id);
    setNewww22(newPositions && newPositions?.matches[21]?.id);
    setNewww23(newPositions && newPositions?.matches[22]?.id);
    setNewww24(newPositions && newPositions?.matches[23]?.id);

    setTeamss1(newPositions && newPositions?.teams[0]?.id);
    setTeamss2(newPositions && newPositions?.teams[1]?.id);
    setTeamss3(newPositions && newPositions?.teams[2]?.id);
    setTeamss4(newPositions && newPositions?.teams[3]?.id);
    setTeamss5(newPositions && newPositions?.teams[4]?.id);
    setTeamss6(newPositions && newPositions?.teams[5]?.id);
    setTeamss7(newPositions && newPositions?.teams[6]?.id);
    setTeamss8(newPositions && newPositions?.teams[7]?.id);
    setTeamss9(newPositions && newPositions?.teams[8]?.id);
    setTeamss10(newPositions && newPositions?.teams[9]?.id);
    setTeamss11(newPositions && newPositions?.teams[10]?.id);
    setTeamss12(newPositions && newPositions?.teams[11]?.id);
    setTeamss13(newPositions && newPositions?.teams[12]?.id);
    setTeamss14(newPositions && newPositions?.teams[13]?.id);
    setTeamss15(newPositions && newPositions?.teams[14]?.id);
    setTeamss16(newPositions && newPositions?.teams[15]?.id);
    setTeamss17(newPositions && newPositions?.teams[16]?.id);
    setTeamss18(newPositions && newPositions?.teams[17]?.id);
    setTeamss19(newPositions && newPositions?.teams[18]?.id);
    setTeamss20(newPositions && newPositions?.teams[19]?.id);
    setTeamss21(newPositions && newPositions?.teams[20]?.id);
    setTeamss22(newPositions && newPositions?.teams[21]?.id);
    setTeamss23(newPositions && newPositions?.teams[21]?.id);
    setTeamss24(newPositions && newPositions?.teams[23]?.id);
  }, [newPositions]);

  console.log("pppppppppppppp", match2);
  const handleDateChange = (newDate) => {
    console.log("aaaaaaa", newDate);
    setDate(newDate);
  };
  console.log("dateeeee", date);
  console.log("timeeee", time);

  const getDate = (date) => {
    const newDate = new Date(date);
    // console.log(
    //   "lll",
    //   newDate.getFullYear() +
    //     " - " +
    //     (newDate.getMonth()+1) +
    //     " - " +
    //     newDate.getDate()
    // );
    return (
      newDate.getFullYear() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getDate()
    );
  };

  const getTime = (time) => {
    const newTime = new Date(time);
    // console.log(
    //   "lll",
    //   newTime.getHours() +
    //     " - " +
    //     newTime.getMinutes() +
    //     " - " +
    //     newTime.getSeconds()
    // );
    return (
      newTime.getHours() +
      ":" +
      newTime.getMinutes() +
      ":" +
      newTime.getSeconds()
    );
  };

  const onAllMatchHandler = async () => {
    // console.log('aaaaaaaaaa',getDate(date));
    // console.log('aaaaaaaaaa',getTime(date));
    // try {
    // console.log("item",name);
    // console.log("response",abbreviation);
    // console.log("fghff:", val);
    console.log(
      "qqqqw",
      teamss1,
      teamss2,
      teamss3,
      teamss4,
      teamss5,
      "adsd",
      newww,
      newww2,
      newww3,
      newww4,
      newww5,
      match2,
      newww24,
      newww19,
      "sssssss",
      teamss18,
      newww18,
      newww17,
      "lllll",
      newww,
      newww2,
      newww3,
      newww4,
      newww5,
      newww6,
      newww7,
      newww8,
      newww9,
      newww10,
      newww11,
      newww12,
      newww13,
      newww14,
      newww15,
      newww16,
      newww17,
      newww18,
      newww19,
      newww20,
      newww21,
      newww22,
      newww23,
      newww24,
      "ttttt",
      teamss1,
      teamss2,
      teamss3,
      teamss4,
      teamss5,
      teamss6,
      teamss7,
      teamss8,
      teamss9,
      teamss10,
      teamss11,
      teamss12,
      teamss13,
      teamss14,
      teamss15,
      teamss16,
      teamss17,
      teamss18,
      teamss19,
      teamss20,
      teamss21,
      teamss22,
      teamss23,
      teamss24,
      "ppppp",
      position1,
      position2,
      position3,
      position4,
      position5,
      position6,
      position7,
      position8,
      position9,
      position10,
      position11,
      position12,
      position13,
      position14,
      position15,
      position16,
      position17,
      position18,
      position19,
      position20,
      position21,
      position22,
      position23,
      position24
    );

    console.log(
      "qwqwqwqwqwqqw",
      date
    );

    const madata = await fetch(
      "https://api.sportzcube.com/api/createGamePlayers/" + gameId,
      {
        method: "POST",
        body: JSON.stringify({
          cutoff_date: dayjs(date).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
          cutoff_time: getTime(time),
          match1_id: teamss1,
          name1: name1,
          position1_id: position1,
          team1_id: newww,

          match2_id: teamss2,
          name2: name2,
          position2_id: position2,
          team2_id: newww2,

          match3_id: teamss3,
          name3: name3,
          position3_id: position3,
          team3_id: newww3,

          match4_id: teamss4,
          name4: name4,
          position4_id: position4,
          team4_id: newww4,

          match5_id: teamss5,
          name5: name5,
          position5_id: position5,
          team5_id: newww5,

          match6_id: teamss6,
          name6: name6,
          position6_id: position6,
          team6_id: newww6,

          match7_id: teamss7,
          name7: name7,
          position7_id: position7,
          team7_id: newww7,

          match8_id: teamss8,
          name8: name8,
          position8_id: position8,
          team8_id: newww8,

          match9_id: teamss9,
          name9: name9,
          position9_id: position9,
          team9_id: newww9,

          match10_id: teamss10,
          name10: name10,
          position10_id: position10,
          team10_id: newww10,

          match11_id: teamss11,
          name11: name11,
          position11_id: position11,
          team11_id: newww11,

          match12_id: teamss12,
          name12: name12,
          position12_id: position12,
          team12_id: newww12,

          match13_id: teamss13,
          name13: name13,
          position13_id: position13,
          team13_id: newww13,

          match14_id: teamss14,
          name14: name14,
          position14_id: position14,
          team14_id: newww14,

          match15_id: teamss15,
          name15: name15,
          position15_id: position15,
          team15_id: newww15,

          match16_id: teamss16,
          name16: name16,
          position16_id: position16,
          team16_id: newww16,

          match17_id: teamss17,
          name17: name17,
          position17_id: position17,
          team17_id: newww17,

          match18_id: teamss18,
          name18: name18,
          position18_id: position18,
          team18_id: newww18,

          match19_id: teamss19,
          name19: name19,
          position19_id: position19,
          team19_id: newww19,

          match20_id: teamss20,
          name20: name20,
          position20_id: position20,
          team20_id: newww20,

          match21_id: teamss21,
          name21: name21,
          position21_id: position21,
          team21_id: newww21,

          match22_id: teamss22,
          name22: name22,
          position22_id: position22,
          team22_id: newww22,

          match23_id: teamss23,
          name23: name23,
          position23_id: position23,
          team23_id: newww23,

          match24_id: teamss24,
          name24: name24,
          position24_id: position24,
          team24_id: newww24,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      }
    );

    const pData = await madata.json();
    console.log("maaaaadataa", pData);

    toast.success(pData.message);
  };
  //delete api

  const deleteHandler = async (gameId) => {
    try {
      const response = await fetch(
        "https://api.sportzcube.com/api/match/" + gameId,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Assuming token is defined
          },
        }
      );

      if (response.ok) {
        getShowGameHandler();
        console.log("Match deleted successfully");
      } else {
        console.error("Failed to delete Match:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting match:", error);
    }
  };

  // console.log("iiiiiiiiiiiiii", newPositions?.teams[0]?.id);
  // console.log("iiiiiiiiiiiiii", newPositions?.matches[0]?.id);

  //get games api

  const [showing, setShowing] = useState([]);
  const GamesShowHandler = async () => {
    //  console.log("gameeeeeee");
    const odata = await fetch("https://api.sportzcube.com/api/games", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const alldata = await odata.json();
    setShowing(alldata.games);
    console.log("gameeeeeeeeeeeeeshowinggggg", alldata);
    console.log("hhhhhhhh", alldata.games);
  };
  // console.log("hamza", respData);
  // console.log("uuuuuu", teamss1);
  if (variant === "dashboard")
    return (
      <>
        <div
          className="p-3 text-white  min-h-[100vh] w-full pr-7"
          style={{
            background: "#082362",
          }}
        >
          <Admintop variant={"dashboard"} showing={onAllMatchHandler} />
          <div className="flex w-full">
            <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-9 py-6 my-4 ml-4">
              <div>Select league</div>
              <div className="flex rows w-[80vh] overflow-y-scroll max-h-50">
                <Grid container gap={1}>
                  {leagues.map((item, index) => (
                    <Grid lg={2.8} key={index}>
                      <div
                        className={`border-1 border-blue-300 cursor-pointer rounded-lg text-white mt-4 pb-10 bg-[#082363] ${
                          selectedLeague !== null ? "cursor-not-allowed" : ""
                        } ${selectedLeague === index ? "text-black" : ""}`}
                        onClick={() => {
                          if (selectedLeague === null) {
                            onCreateGameHandler(index);
                            setSelectedLeague(index);
                          }
                        }}
                      >
                        <div>
                          {selectedLeague === index ? (
                            <CircleIcon style={{ fill: "#2196FE" }} />
                          ) : (
                            <CircleIcon />
                          )}
                        </div>
                        <div className="w-[15vh] flex text-center justify-center h-full items-center">
                          {item.abbreviation}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
            {selectedLeague !== null && (
              <>
                <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 py-6 pb-0  ml-4 my-4 w-[70vh]">
                  <div> Select Date and Time</div>
                  <div className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        className="bg-white w-full h-[8.5vh]"
                        value={date}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="mt-4 ">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          value={time}
                          onChange={(newValue) => setTime(newValue)}
                          className="w-full"
                          fontSize="small"
                          label="Select time "
                          style={{ background: "white" }}
                          sx={{
                            background: "white",
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </>
            )}

            {/* <BasicTable /> */}
          </div>
          {selectedLeague !== null && (
            <>
              <div className="ml-4"> Matches</div>
              <div className="flex rows ">
                <Grid container gap={1} className=" max-h-70">
                  {matches.map((item, index) => (
                    <>
                      <Grid xs={3.9} lg={2.9}>
                        <AdminTeamComponent1
                          variant={"text"}
                          responseData={respData}
                          item={item}
                          del={deleteHandler}
                          // matches={item}
                        />
                      </Grid>
                    </>
                  ))}
                  <Grid xs={3.9} lg={2.9}>
                    <AdminTeamComponent1
                      variant={"new"}
                      responseData={respData}
                      gameidd={gameId}
                      leagId={leagueId}
                      fresh={getMatchesHandler}
                      ggg={getShowGameHandler}
                      team1={team1}
                      setTeam1={setTeam1}
                      team2={team2}
                      setTeam2={setTeam2}
                    />
                  </Grid>
                </Grid>
              </div>
            </>
          )}

          {selectedLeague !== null && (
            <>
              {" "}
              <div className="mx-4 mt-4 text-lg"> Players Selection </div>
              <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  mx-4 mt-4 w-full">
                <div className="text-lg">
                  {newPositions && newPositions?.positions[0]?.position}
                </div>
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss1}
                  setTeamss1={setTeamss1}
                  setMatchId={setNewww}
                  matchId={newww}
                  name1={name1}
                  setName1={setName1}
                  match1={match1}
                  setMatch1={setMatch1}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss2}
                  matchId={newww2}
                  setMatchId={setNewww2}
                  setTeamss1={setTeamss2}
                  name1={name2}
                  setName1={setName2}
                  match1={match2}
                  setMatch1={setMatch2}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss3}
                  matchId={newww3}
                  setMatchId={setNewww3}
                  setTeamss1={setTeamss3}
                  name1={name3}
                  setName1={setName3}
                  match1={match3}
                  setMatch1={setMatch3}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss4}
                  matchId={newww4}
                  setMatchId={setNewww4}
                  setTeamss1={setTeamss4}
                  name1={name4}
                  setName1={setName4}
                  match1={match4}
                  setMatch1={setMatch4}
                />
              </div>
              <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  ml-4 mt-4 w-full">
                <div className="text-lg">
                  {newPositions && newPositions?.positions[1]?.position}
                </div>
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss5}
                  matchId={newww5}
                  setMatchId={setNewww5}
                  setTeamss1={setTeamss5}
                  name1={name5}
                  setName1={setName5}
                  match1={match5}
                  setMatch1={setMatch5}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss6}
                  setMatchId={setNewww6}
                  setTeamss1={setTeamss6}
                  name1={name6}
                  setName1={setName6}
                  match1={match6}
                  setMatch1={setMatch6}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss7}
                  setMatchId={setNewww7}
                  setTeamss1={setTeamss7}
                  name1={name7}
                  setName1={setName7}
                  match1={match7}
                  setMatch1={setMatch7}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss8}
                  setMatchId={setNewww8}
                  setTeamss1={setTeamss8}
                  name1={name8}
                  setName1={setName8}
                  match1={match8}
                  setMatch1={setMatch8}
                />
              </div>
              <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  ml-4 mt-4 w-full">
                <div className="text-lg">
                  {newPositions && newPositions?.positions[2]?.position}
                </div>
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss9}
                  setMatchId={setNewww9}
                  setTeamss1={setTeamss9}
                  name1={name9}
                  setName1={setName9}
                  match1={match9}
                  setMatch1={setMatch9}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss10}
                  setMatchId={setNewww10}
                  setTeamss1={setTeamss10}
                  name1={name10}
                  setName1={setName10}
                  match1={match10}
                  setMatch1={setMatch10}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss11}
                  setMatchId={setNewww11}
                  setTeamss1={setTeamss11}
                  name1={name11}
                  setName1={setName11}
                  match1={match11}
                  setMatch1={setMatch11}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss12}
                  setMatchId={setNewww12}
                  setTeamss1={setTeamss12}
                  name1={name12}
                  setName1={setName12}
                  match1={match12}
                  setMatch1={setMatch12}
                />
              </div>
              <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  ml-4 mt-4 w-full">
                <div className="text-lg">
                  {newPositions && newPositions?.positions[3]?.position}
                </div>
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss13}
                  setTeamss1={setTeamss13}
                  name1={name13}
                  setName1={setName13}
                  setMatchId={setNewww13}
                  match1={match13}
                  setMatch1={setMatch13}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss14}
                  setMatchId={setNewww14}
                  setTeamss1={setTeamss14}
                  name1={name14}
                  setName1={setName14}
                  match1={match14}
                  setMatch1={setMatch14}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss15}
                  setMatchId={setNewww15}
                  setTeamss1={setTeamss15}
                  name1={name15}
                  setName1={setName15}
                  match1={match15}
                  setMatch1={setMatch15}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss16}
                  setTeamss1={setTeamss16}
                  setMatchId={setNewww16}
                  name1={name16}
                  setName1={setName16}
                  match1={match16}
                  setMatch1={setMatch16}
                />
              </div>
              <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  ml-4 mt-4 w-full">
                <div className="text-lg">
                  {newPositions && newPositions?.positions[4]?.position}
                </div>
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss17}
                  setMatchId={setNewww17}
                  setTeamss1={setTeamss17}
                  name1={name17}
                  setName1={setName17}
                  match1={match17}
                  setMatch1={setMatch17}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss18}
                  setMatchId={setNewww18}
                  setTeamss1={setTeamss18}
                  name1={name18}
                  setName1={setName18}
                  match1={match18}
                  setMatch1={setMatch18}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss19}
                  setMatchId={setNewww19}
                  setTeamss1={setTeamss19}
                  name1={name19}
                  setName1={setName19}
                  match1={match19}
                  setMatch1={setMatch19}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss20}
                  setMatchId={setNewww20}
                  setTeamss1={setTeamss20}
                  name1={name20}
                  setName1={setName20}
                  match1={match20}
                  setMatch1={setMatch20}
                />
              </div>
              <div className="border-2 border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  ml-4 mt-4 w-full">
                <div className="text-lg">
                  {newPositions && newPositions?.positions[5]?.position}
                </div>
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss21}
                  setMatchId={setNewww21}
                  setTeamss1={setTeamss21}
                  name1={name21}
                  setName1={setName21}
                  match1={match21}
                  setMatch1={setMatch21}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss22}
                  setMatchId={setNewww22}
                  setTeamss1={setTeamss22}
                  name1={name22}
                  setName1={setName22}
                  match1={match22}
                  setMatch1={setMatch22}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss23}
                  setMatchId={setNewww23}
                  setTeamss1={setTeamss23}
                  name1={name23}
                  setName1={setName23}
                  match1={match23}
                  setMatch1={setMatch23}
                />
                <AdminGoaltender
                  matches={matches}
                  teamss1={teamss24}
                  setMatchId={setNewww24}
                  setTeamss1={setTeamss24}
                  name1={name24}
                  setName1={setName24}
                  match1={match24}
                  setMatch1={setMatch24}
                />
              </div>
            </>
          )}
        </div>
      </>
    );

  if (variant === "grids")
    return (
      <>
        <div
          className="p-3 text-white min-h-[100vh] w-full pr-4"
          style={{
            background: "#082362",
          }}
        >
          <Admintop variant={"grids"} />

          <div className="flex rows ">
            <Grid container gap={1} className="">
              <>
                {showing.map((item, index) => (
                  <Grid xs={3.9} lg={2.9}>
                    <TeamDetails
                      style={{
                        border: index ? "0px" : "2px solid red",
                      }}
                      item={item}
                    />
                  </Grid>
                ))}
              </>
            </Grid>
          </div>
        </div>
      </>
    );

  if (variant === "table")
    return (
      <>
        <div
          className="p-3 text-white min-h-[100vh] w-full pr-4"
          style={{
            background: "#082362",
          }}
        >
          <Admintop variant={"table"} />
          <div className="">
            <BasicTable />
          </div>
        </div>
      </>
    );

  if (variant === "user")
    return (
      <>
        <div
          className="p-3 text-white min-h-[100vh] w-full pr-4"
          style={{
            background: "#082362",
          }}
        >
          <Admintop variant={"user"} />
          <div className="">
            <UserManagementTable />
          </div>
        </div>
      </>
    );

  if (variant === "settings")
    return (
      <>
        <div
          className="p-3 text-white min-h-[100vh] w-full pr-4"
          style={{
            background: "#082362",
          }}
        >
          <Admintop variant={"settings"} />
          <div className="">
            <AdminSettings />
          </div>
        </div>
      </>
    );

  if (variant === "league")
    return (
      <>
        <div
          className="p-3 text-white min-h-[100vh] w-full pr-4"
          style={{
            background: "#082362",
          }}
        >
          <Admintop variant={"league"} leaguesrefresh={getAllHandler} />
          <div className="flex rows ">
            <Grid container gap={1} className="">
              {allLeagues.map((item, index) => (
                <Grid key={index} xs={3.9} lg={2.9}>
                  <League
                    leagueId={item.id}
                    leaguesrefresh={getAllHandler}
                    alldata={item}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </>
    );
}
