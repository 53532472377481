import React, { useState } from "react";
import CustomTextfield from "./CustomTextfield";
import CreateIcon from "@mui/icons-material/Create";
import CustomModel from "./CustomModel";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useAuth } from "../Context/AuthContext";

export default function LeagueList({ teadata, refresh }) {
  const [open, setOpen] = React.useState(false);
  const AddLeagueHandler = () => {
    setOpen(true);
  };
  const { token } = useAuth();

  const handleClose = () => setOpen(false);

  const [name, setName] = useState(teadata?.name);
  const [abbreviation, setAbbreviation] = useState(teadata?.abbreviation);
  const editlHandler = async () => {
    console.log("gggg", teadata.id, name, abbreviation);
    const leaguedata = await fetch(
      "https://api.sportzcube.com/api/updateTeam/" + teadata.id,
      {
        method: "PUT",
        body: JSON.stringify({ name, abbreviation }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const jdata = await leaguedata.json();
    console.log(jdata);

    if (leaguedata.ok) {
      console.log("All teams updated successfully");
      toast.success("Edit successfully");
      handleClose();
      refresh();
      // Handle any further logic after successful update
    } else {
      console.error("Failed to update all teams:", leaguedata.statusText);
      toast.error(leaguedata.message);
    }
  };

  const termconditionText = () => (
    <>
      <hr />
      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          placeholder={"Edit Team Name "}
          style={{ borderRadius: "10px" }}
          value={name}
          onChange={setName}
        />
      </div>

      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          placeholder={"Edit team abbreviation"}
          style={{ borderRadius: "10px" }}
          value={abbreviation}
          onChange={setAbbreviation}
        />
      </div>
      <div className="mt-4 align-baseline">
        <Button
          color="white"
          variant="outlined"
          onClick={handleClose}
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
        >
          Cancel
        </Button>
        <Button
          color="white"
          variant="contained"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
          sx={{ background: "#2196FE" }}
          onClick={editlHandler}
        >
          Save
        </Button>
      </div>
    </>
  );

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={open}
        variant={"league"}
        setOpen={setOpen}
        style={{ width: "80vh" }}
        // innerText={innerText}
        termconditionText={termconditionText}
        headingText={"Edit League"}
      />
      <div className="flex">
        <div className="mt-4 w-full">
          <CustomTextfield
            type={"text"}
            size={"small"}
            variant={"outlined"}
            disabled={true}
            style={{ borderRadius: "5px" }}
            value={teadata?.name}
          />
        </div>
        <div className="mt-4 w-full px-2 ">
          <CustomTextfield
            type={"text"}
            size={"small"}
            variant={"outlined"}
            style={{ borderRadius: "5px" }}
            disabled={true}
            value={teadata?.abbreviation}
          />
        </div>

        <div
          className="flex justify-center items-center text-center cursor-pointer bg-[#2196FE] px-3 mt-4"
          onClick={AddLeagueHandler}
        >
          <CreateIcon fontSize="small" />
        </div>
      </div>
    </>
  );
}
