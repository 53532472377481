import React, { useEffect, useState } from "react";
import CustomTextfield from "./CustomTextfield";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CreateIcon from "@mui/icons-material/Create";
import { toast } from "react-toastify";
import { useAuth } from "../Context/AuthContext";
import { Button } from "@mui/material";
import CustomModel from "./CustomModel";

export default function PositionList({
  name,
  Sname,
  abbr,
  Sabbr,
  isDataPresent,
  id1,
  id2,
  id3,
  id4,
  id5,
  id6,

  refresh,
}) {
  const [name1, setName1] = useState(name);
  const [id, setId] = useState(id1);
  const [abbreviation, setAbbreviation] = useState(abbr);
  useEffect(() => {
    setName1(name);
    setAbbreviation(abbr);
    console.log("32332323232: ", id1);
  }, [name, abbr, id1]);

  const { token } = useAuth();

  const [open, setOpen] = React.useState(false);
  const AddLeagueHandler = () => {
    setOpen(true);
    // getHandler();
  };

  const handleClose = () => setOpen(false);

  const editlHandler = async () => {
    console.log(name1, abbreviation);
    const leaguedata = await fetch(
      "https://api.sportzcube.com/api/updatePosition/" + id1,
      {
        method: "PUT",
        body: JSON.stringify({ position: name1, abbreviation }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const jdata = await leaguedata.json();
    console.log(jdata);

    if (leaguedata.ok) {
      refresh();
      console.log("All Positions updated successfully");
      toast.success("Edit successfully");
      handleClose();

      // Handle any further logic after successful update
    } else {
      console.error("Failed to update Position:", leaguedata.statusText);
      toast.error(leaguedata.message);
    }
  };
  console.log("aaaaaa", name1, abbreviation);
  const termconditionText = () => (
    <>
      <hr />
      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          placeholder={"Edit Position Name "}
          style={{ borderRadius: "10px" }}
          value={name1}
          onChange={setName1}
        />
      </div>

      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          placeholder={"Edit Position abbreviation"}
          style={{ borderRadius: "10px" }}
          value={abbreviation}
          onChange={setAbbreviation}
        />
      </div>
      <div className="mt-4 align-baseline">
        <Button
          color="white"
          variant="outlined"
          onClick={handleClose}
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
        >
          Cancel
        </Button>
        <Button
          color="white"
          variant="contained"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
          sx={{ background: "#2196FE" }}
          onClick={editlHandler}
        >
          Save
        </Button>
      </div>
    </>
  );

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={open}
        variant={"league"}
        setOpen={setOpen}
        style={{ width: "80vh" }}
        // innerText={innerText}
        termconditionText={termconditionText}
        headingText={"Edit Position"}
      />
      <div className="flex">
        {isDataPresent === true ? (
          <>
            <div className="mt-4 w-full">
              <CustomTextfield
                type={"text"}
                size={"small"}
                variant={"outlined"}
                disabled={true}
                value={name}
                onChange={Sname}
                style={{ borderRadius: "5px" }}
                placeholder={"Enter postion"}
              />
            </div>
            <div className="mt-4 w-full px-2 ">
              <CustomTextfield
                type={"text"}
                size={"small"}
                variant={"outlined"}
                disabled={true}
                value={abbr}
                onChange={Sabbr}
                style={{ borderRadius: "5px" }}
                placeholder={"Enter abbreviation"}
              />
            </div>
            <div
              className="flex justify-center items-center text-center cursor-pointer bg-[#2196FE] px-3 mt-4"
              onClick={AddLeagueHandler}
            >
              <CreateIcon fontSize="small" />
            </div>

            {/* <div className="flex justify-center items-center text-center cursor-pointer bg-[#2196FE] px-3 mt-4">
              <SaveAsIcon fontSize="small" />
            </div> */}
          </>
        ) : (
          <>
            <div className="mt-4 w-full">
              <CustomTextfield
                type={"text"}
                size={"small"}
                variant={"outlined"}
                value={name}
                onChange={Sname}
                style={{ borderRadius: "5px" }}
                placeholder={"Enter postion"}
              />
            </div>
            <div className="mt-4 w-full px-2 ">
              <CustomTextfield
                type={"text"}
                size={"small"}
                variant={"outlined"}
                value={abbr}
                onChange={Sabbr}
                style={{ borderRadius: "5px" }}
                placeholder={"Enter abbreviation"}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
