import { Button, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextfield from "./CustomTextfield";
import SearchIcon from "@mui/icons-material/Search";
import LeagueList from "./LeagueList";
import { useLocation } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import CustomModel from "./CustomModel";
import { toast } from "react-toastify";
import PositionList from "./PositionList";

export default function ExtendteamComponent() {
  const buttonStyle = {
    padding: "3px 8px",
    borderRadius: "4px",
    margin: "4px",
  };
  const handleClose = () => setOpen(false);
  const { token } = useAuth();
  const location = useLocation();
  const val = location.state?.leagueId;

  console.log(val);
  const [id, setId] = useState(val);

  const [allTeams, setAllTeams] = useState([]);
  const idHandler = async () => {
    console.log("ppppppp");
    const data = await fetch(
      "https://api.sportzcube.com/api/showLeague/" + val,
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const jdata = await data.json();
    console.log("vhggf", jdata?.teams);
    console.log("responseeee", jdata);
    setId(jdata.showleague / { val });
    setAllTeams(jdata?.teams);
  };
  console.log("statee", allTeams);

  useEffect(() => {
    idHandler();

    positionHandler();
  }, []);

  const [open, setOpen] = React.useState(false);
  const AddLeagueHandler = () => {
    setOpen(true);
  };
  const termconditionText = () => (
    <>
      <hr />
      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          value={name}
          onChange={setName}
          placeholder={"Enter Team Name"}
          style={{ borderRadius: "10px" }}
        />
      </div>

      <div className=" mt-4 align-baseline">
        <CustomTextfield
          type={"text"}
          size={"small"}
          variant={"outlined"}
          value={abbreviation}
          onChange={setAbbreviation}
          placeholder={"Enter Team Abbreviation"}
          style={{ borderRadius: "10px" }}
        />
      </div>
      <div className="mt-4 align-baseline">
        <Button
          color="white"
          variant="outlined"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
        >
          Cancel
        </Button>
        <Button
          color="white"
          variant="contained"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
          sx={{ background: "#2196FE" }}
          onClick={onCreateHandler}
        >
          Save
        </Button>
      </div>
    </>
  );

  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  // const [leaguid, setLeagueId] = useState("");
  const onCreateHandler = async () => {
    // try {
    // console.log("item",name);
    // console.log("response",abbreviation);
    // console.log("fghff:", val);
    const postdata = await fetch("https://api.sportzcube.com/api/createTeam", {
      method: "POST",
      body: JSON.stringify({ name, abbreviation, league_id: val }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });
    // console.log("ttttttt", await data.text());

    // const jsonData = ;

    //   const d = await data.json();
    //   console.log("ttttttt1", d);
    // };

    const responseData = await postdata.json();
    setName(responseData?.league?.name);
    setAbbreviation(responseData?.league?.abbreviation);
    setId(responseData?.league?.val);

    if (postdata.ok) {
      console.log(responseData);
      console.log(postdata.ok);
      toast.success(responseData.message);
      idHandler();
      handleClose();
    } else {
      console.error(responseData.message);
      console.error(responseData.error);
      toast.error(responseData.message);
    }

    console.log("Team save response:", responseData);
    // } catch (err) {
    //   console.log("lll", err);
    // }
  };

  //get teams api

  //  const getTeamHandler = async () => {
  //    const teamdata = await fetch(
  //      "https://api.sportzcube.com/api/teams",
  //      {
  //        method: "GET",
  //        // body: JSON.stringify({ email, password }),
  //        headers: {
  //          "Content-Type": "application/json",
  //          Authorization: `Bearer ${token}`,
  //        },
  //      }
  //    );
  //    // console.log(data);
  //    const tdata = await teamdata.json();
  //    console.log("hgfgfg", tdata);
  //    console.log("jajjajaj",teamdata)
  //    setAllTeams(tdata.teams);
  //    console.log("hamzaaaa",allTeams);
  //  };

  //postion api

  // const [leaguid, setLeagueId] = useState("");
  const [name1, setName1] = useState("");
  const [abbreviation1, setAbbreviation1] = useState("");
  const [id1, setId1] = useState(val);

  const [name2, setName2] = useState("");
  const [abbreviation2, setAbbreviation2] = useState("");
  const [id2, setId2] = useState(val);

  const [name3, setName3] = useState("");
  const [abbreviation3, setAbbreviation3] = useState("");
  const [id3, setId3] = useState(val);

  const [name4, setName4] = useState("");
  const [abbreviation4, setAbbreviation4] = useState("");
  const [id4, setId4] = useState(val);

  const [name5, setName5] = useState("");
  const [abbreviation5, setAbbreviation5] = useState("");
  const [id5, setId5] = useState(val);

  const [name6, setName6] = useState("");
  const [abbreviation6, setAbbreviation6] = useState("");
  const [id6, setId6] = useState(val);

  const onPositionHandler = async () => {
    console.log("iddddddddd", val);
    // try {
    // console.log("item",name);
    // console.log("response",abbreviation);
    // console.log("fghff:", val);
    const positiondata = await fetch(
      "https://api.sportzcube.com/api/createPosition/" + val,
      {
        method: "POST",
        body: JSON.stringify({
          position_1: name1,
          abbreviation_1: abbreviation1,
          position_2: name2,
          abbreviation_2: abbreviation2,
          position_3: name3,
          abbreviation_3: abbreviation3,
          position_4: name4,
          abbreviation_4: abbreviation4,
          position_5: name5,
          abbreviation_5: abbreviation5,
          position_6: name6,
          abbreviation_6: abbreviation6,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      }
    );
    // console.log("ttttttt", await data.text());

    // const jsonData = ;

    //   const d = await data.json();
    //   console.log("ttttttt1", d);
    // };

    const pData = await positiondata.json();
    console.log("positiondataa", pData);
    setName1(pData?.league?.name);
    setAbbreviation1(pData?.league?.abbreviation);
    setId1(pData?.league?.val);

    setName2(pData?.league?.name);
    setAbbreviation2(pData?.league?.abbreviation);
    setId2(pData?.league?.val);

    setName3(pData?.league?.name);
    setAbbreviation3(pData?.league?.abbreviation);
    setId3(pData?.league?.val);

    setName4(pData?.league?.name);
    setAbbreviation4(pData?.league?.abbreviation);
    setId4(pData?.league?.val);

    setName5(pData?.league?.name);
    setAbbreviation5(pData?.league?.abbreviation);
    setId5(pData?.league?.val);

    setName6(pData?.league?.name);
    setAbbreviation6(pData?.league?.abbreviation);
    setId6(pData?.league?.val);

    if (positiondata.ok) {
      console.log(pData);
      console.log(positiondata.ok);
      toast.success(pData.message);
      // idHandler();
      handleClose();
    } else {
      console.error(pData.message);
      console.error(pData.error);
      toast.error(pData.message);
    }

    console.log("Position save response:", pData);
    // } catch (err) {
    //   console.log("lll", err);
    // }
  };

  //get postion api
  const [position, setPosition] = useState([]);
  const positionHandler = async () => {
    //  console.log("pppppppp")
    const posdata = await fetch(
      "https://api.sportzcube.com/api/positions/" + val,
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const pdata = await posdata.json();
    console.log("kkkkkkkkppppppp", pdata?.data);
    setPosition(pdata?.data);
    if (pdata?.length !== 0) {
      setName1(pdata?.data[0]?.position);
      setAbbreviation1(pdata?.data[0]?.abbreviation);
      setId1(pdata?.data[0]?.id);

      setName2(pdata?.data[1]?.position);
      setAbbreviation2(pdata?.data[1]?.abbreviation);
      setId2(pdata?.data[1]?.id);

      setName3(pdata.data[2]?.position);
      setAbbreviation3(pdata.data[2]?.abbreviation);
      setId3(pdata.data[2]?.id);

      setName4(pdata.data[3]?.position);
      setAbbreviation4(pdata.data[3]?.abbreviation);
      setId4(pdata.data[3]?.id);

      setName5(pdata.data[4]?.position);
      setAbbreviation5(pdata.data[4]?.abbreviation);
      setId5(pdata.data[4]?.id);

      setName6(pdata.data[5]?.position);
      setAbbreviation6(pdata.data[5]?.abbreviation);
      setId6(pdata.data[5]?.id);
    }
    console.log("dataaaaa", pdata.data);
    console.log("postion responseeee", pdata);
  };

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={open}
        variant={"league"}
        setOpen={setOpen}
        style={{ width: "80vh" }}
        // innerText={innerText}
        termconditionText={termconditionText}
        headingText={"Add Team here"}
      />
      <div
        className="flex p-3 text-white  min-h-[100vh] w-full pr-7"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <div className="border-1 border-blue-300 rounded-sm px-4 py-4 my-4 ml-4 text-white bg-[#10368D]">
          <div className="flex items-center justify-between">
            <div>Teams</div>
            <div>
              <Button
                color="white"
                variant="contained"
                style={buttonStyle}
                sx={{ background: "#2196FE" }}
                onClick={AddLeagueHandler}
              >
                Add Team
              </Button>
            </div>
          </div>

          <div>
            {allTeams.map((item, index) => (
              <LeagueList teadata={item} refresh={idHandler} />
            ))}
          </div>
        </div>

        <div className="border-1 border-blue-300 rounded-sm px-4 py-4 my-4 ml-4 text-white bg-[#10368D]">
          <div className="flex justify-between">
            <div className="pt-2">Positions</div>
            {position.length === 0 ? (
              <div>
                <Button
                  color="white"
                  variant="contained"
                  style={buttonStyle}
                  sx={{ background: "#2196FE" }}
                  onClick={onPositionHandler}
                >
                  Save Postions
                </Button>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div>
            <PositionList
              isDataPresent={position.length > 0 ? true : false}
              name={name1}
              Sname={setName1}
              abbr={abbreviation1}
              Sabbr={setAbbreviation1}
              id1={id1}
              refresh={positionHandler}
            />
            <PositionList
              isDataPresent={position.length > 0 ? true : false}
              name={name2}
              Sname={setName2}
              abbr={abbreviation2}
              Sabbr={setAbbreviation2}
              id1={id2}
              refresh={positionHandler}
            />
            <PositionList
              isDataPresent={position.length > 0 ? true : false}
              name={name3}
              Sname={setName3}
              abbr={abbreviation3}
              Sabbr={setAbbreviation3}
              id1={id3}
              refresh={positionHandler}
            />
            <PositionList
              isDataPresent={position.length > 0 ? true : false}
              name={name4}
              Sname={setName4}
              abbr={abbreviation4}
              Sabbr={setAbbreviation4}
              id1={id4}
              refresh={positionHandler}
            />
            <PositionList
              isDataPresent={position.length > 0 ? true : false}
              name={name5}
              Sname={setName5}
              abbr={abbreviation5}
              Sabbr={setAbbreviation5}
              id1={id5}
              refresh={positionHandler}
            />
            <PositionList
              isDataPresent={position.length > 0 ? true : false}
              name={name6}
              Sname={setName6}
              abbr={abbreviation6}
              Sabbr={setAbbreviation6}
              id1={id6}
              refresh={positionHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
}
