import { InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import CustomTextfield from "./CustomTextfield";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDropdown from "./CustomDropdown";
import { useState } from "react";

export default function AdminGoaltender({
  setTeam1,
  matches,
  type,
  teamss1,
  setTeamss1,
  name1,
  setName1,
  match1,
  setMatch1,
  setMatchId,
}) {
  console.log("kkkkk", match1);
  const [newTeam, setNewTeam] = useState([]);
  console.log("asaas", newTeam);
  useEffect(() => {
    console.log("qwe", match1);
    var temp = match1 && `${match1}`.split(",");
    console.log("adasdasds", matches);
    setNewTeam(temp);
    // console.log('lllll', matches)
    // setTeamss1(matches[0]?.id)
  }, [match1]);
  console.log("q1q1", teamss1);
  return (
    <>
      <div className="flex">
        <div className="mt-4 w-full">
          <CustomDropdown
            name={"Select Match"}
            team={matches}
            label={"Select Match"}
            teamss={setMatch1}
            setTeamss1={setTeamss1}
            type={"gameplayers"}
          />
        </div>
        <div className="mt-4 w-full px-4 ">
          <CustomDropdown
            name={"Select Team"}
            team={newTeam}
            label={"Select Team"}
            teamss={setTeam1}
            type={"gameplayers"}
            variant={"team"}
            setMatchId={setMatchId}
          />
        </div>
        <div className="mt-4 w-full ">
          <CustomTextfield
            type={"text"}
            size={"small"}
            value={name1}
            onChange={setName1}
            variant={"outlined"}
            style={{ borderRadius: "0" }}
            placeholder={"Enter Player name"}
            // inputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">
            //       <SearchIcon />
            //     </InputAdornment>
            //   ),
            // }}
          />
        </div>
        <div className="flex justify-center items-center text-center ml-3 bg-[#2196FE] px-3 mt-4">
          <CreateIcon fontSize="small" />
        </div>
      </div>
    </>
  );
}
