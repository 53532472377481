import React, { useEffect, useState } from "react";
import NavApp from "../components/NavApp";
import CustomTextfield from "../components/CustomTextfield";
import { InputAdornment } from "@mui/material";
import Profile from "../components/Profile";
import CustomButton from "../components/CustomButton";
import SearchIcon from "@mui/icons-material/Search";
import PreviousGameTable from "../components/PreviousGameTable";
import { useAuth } from "../Context/AuthContext";

export default function PreviousCube() {
  const { token } = useAuth();
  const [searchText, setSearchText] = useState("");

  //get api

  const [listingg, setList] = React.useState("");
  const [rows, setRows] = useState([]);
  const listHandler = async () => {
    //  console.log("gameeeeeee");
    const ldata = await fetch("https://api.sportzcube.com/api/getUserGames", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const lidata = await ldata.json();

    console.log("User Games", lidata);
    setRows(lidata.games);
    setList(lidata.current_user);
    // console.log("ooooooooo", listingg);

    // console.log("hhhhhhhh", alldata.games);
  };

  // console.log(listingg);

  useEffect(() => {
    listHandler();
  }, []);

  return (
    <>
      <div
        className="pb-4 text-white min-h-[100vh]"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <NavApp />
        <div className="text-white text-2xl pt-4 pl-4 pb-4 mt-3 mb-5">
          {" "}
          Previous Games
        </div>
        {/* <div className="text-white text-lg pt-4 pl-4"> Head To Head</div>
        <div className="text-gray-400 text-base pl-4 pb-4">
          Click on current player to view their selected team.
        </div> */}

        <div className="flex justify-between  ml-4 pb-4">
          <div className="w-3/4 block">
            <CustomTextfield
              type={"text"}
              size={"small"}
              variant={"outlined"}
              placeholder={"search"}
              value={searchText}
              onChange={setSearchText}
              style={{ border: "1px solid border green", borderRadius: "20px" }}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        {token && <Profile variant={"previouscube"} list={listingg} />}
        <div className="px-4 mt-6">
          {rows && <PreviousGameTable rows={rows} searchText={searchText} />}
        </div>
      </div>
    </>
  );
}
