import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration"; 

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const Time = ({ targetDate, targetTime, setSecondsRemaining }) => {
  const calculateTimeLeft = () => {
    if (!targetDate || !targetTime) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    // Ensure that the date is in YYYY-MM-DD format
    const formattedDate = dayjs(targetDate).format("YYYY-MM-DD");

    // Combine date and time into a valid ISO 8601 format with EST timezone
    const combinedDateTime = `${formattedDate}T${targetTime}`; // EST during daylight saving time

    console.log("Combined DateTime (EST):", combinedDateTime);

    // Parse combined date and time as EST
    const estDateTime = dayjs.tz(combinedDateTime, "America/New_York");

    const now = dayjs();
    const difference = estDateTime.diff(now);

    if (difference > 0) {
      // Use the duration plugin to calculate the time difference
      const duration = dayjs.duration(difference);

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setSecondsRemaining && setSecondsRemaining(Math.floor(difference / 1000));

      return { days, hours, minutes, seconds };
    } else {
      setSecondsRemaining && setSecondsRemaining(0);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate, targetTime]);

  const { days, hours, minutes, seconds } = timeLeft;

  const formatTimeUnit = (unit) => String(unit).padStart(2, "0");

  return (
    <div className="flex text-center items-center justify-center">
      {(seconds !== 0 || hours !== 0 || (minutes !== 0 && days !== 0)) && (
        <>
          {days > 0 && (
            <div className="w-9 md:w-auto">
              <span className="text-2xl md:text-4xl">{days}</span> :{" "}
            </div>
          )}
          {hours > 0 && (
            <div className="w-9 md:w-auto">
              <span className="text-2xl md:text-4xl">{hours}</span> :{" "}
            </div>
          )}
          {minutes > 0 && (
            <div className="w-9 md:w-auto">
              <span className="text-2xl md:text-4xl">{minutes}</span> :{" "}
            </div>
          )}
          <div className="w-9 md:w-auto">
            <span className="text-2xl md:text-4xl">{seconds}</span>
          </div>
        </>
      )}
      {days === 0 && hours === 0 && minutes === 0 && seconds === 0 && (
        <div className="w-auto">
          <span className="text-2xl md:text-4xl sm:text-base text-red-600">
            Time's Up! Come back tomorrow for a new SportzCube
          </span>
        </div>
      )}
    </div>
  );
};

export default Time;
