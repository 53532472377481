import React, { useEffect, useState } from "react";
import CustomButton from "../components/CustomButton";
import { Button, InputAdornment } from "@mui/material";
import CustomTextfield from "../components/CustomTextfield";
import NavApp from "../components/NavApp";
import SearchIcon from "@mui/icons-material/Search";
import BasicTable from "../components/BasicTable";
import CustomTable from "../components/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

export default function HeadToHead() {
  const buttonStyle = {
    padding: "10px 20px",
    borderRadius: "40px",
  };
  const { token } = useAuth();
  const location = useLocation();
  const val = location?.state?.gameId;
  const [searchText, setSearchText] = useState("");

  console.log("idddddddddddddddddddddd", val);

  const navigate = useNavigate();
  const LeaderHandler = () => {
    navigate("/leaderboard");
  };

  //get api

  const [leaderBoard, setLeaderBoard] = React.useState([]);
  const boardHandler = async () => {
    //  console.log("gameeeeeee");
    const leaddata = await fetch(
      "https://api.sportzcube.com/api/getGameUsers/" + val,
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const leadata = await leaddata.json();

    console.log("Detail Game Users", leadata);
    setLeaderBoard(leadata?.users);

    // console.log("hhhhhhhh", alldata.games);
  };
  console.log("oooooo", leaderBoard);
  useEffect(() => {
    boardHandler();
  }, []);
  return (
    <>
      <div
        className="pb-4 text-white min-h-[100vh]"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <NavApp />
        <div className="text-white text-2xl pt-4 pl-6 pb-4 mt-3">
          {" "}
          Head to Head
        </div>
        {/* <div className="text-white text-lg pt-4 pl-4"> Head To Head</div>
        <div className="text-gray-400 text-base pl-4 pb-4">
          Click on current player to view their selected team.
        </div> */}

        <div className="flex justify-between mt-5 ml-4 pb-4">
          <div className="w-3/4 block">
            <CustomTextfield
              type={"text"}
              size={"small"}
              variant={"outlined"}
              placeholder={"search"}
              value={searchText}
              onChange={setSearchText}
              style={{ border: "1px solid border green", borderRadius: "20px" }}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        {leaderBoard && (
          <CustomTable rows={leaderBoard} idd={val} searchText={searchText} />
        )}

        <div className="text-center items-center my-6 cursor-pointer">
          <Button
            color="white"
            onClick={LeaderHandler}
            variant="outlined"
            style={buttonStyle}
          >
            Season LeaderBoard
          </Button>
        </div>
      </div>
    </>
  );
}
