import { InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextfield from "../components/CustomTextfield";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CustomButton from "../components/CustomButton";
import CustomModel from "../components/CustomModel";
import { toast } from "react-toastify";
import { useAuth } from "../Context/AuthContext";

export default function ForgotPassword() {
  const [open, setOpen] = React.useState(false);

  //   const handleSetOpen = (val) => {
  //     setOpen(val);
  //   };
  const { token } = useAuth();
  const { newEmail } = useAuth();

  const onForgetPasswordHandler = () => {
    setOpen(true);
  };
  const [email, setEmail] = useState("");
  const onforgotHandler = async () => {
    console.log("aaaaa");
    const postdata = await fetch(
      "https://api.sportzcube.com/api/recoveryEmailUser",
      {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }
    );

    const responseData = await postdata.json();
    console.log("fffffffffff", responseData);
    onForgetPasswordHandler();
    if (postdata.ok) {
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
    }
  };

  return (
    <>
      <CustomModel
        buttonHide={true}
        open={open}
        variant={"email"}
        email={email}
        setOpen={setOpen}
      />
      <div className="flex justify-center items-center  bg-[#00072E] h-screen">
        <div className="text-white text-center text-xl h-[65vh] p-11 bg-[#00072E] bg-max-w-xs w-full lg:w-1/2 ">
          Enter your current email so we could sent you password change link
          <div className="mb-6 mt-4 ">
            <CustomTextfield
              type={"email"}
              size={"small"}
              value={email}
              onChange={setEmail}
              variant={"filled"}
              placeholder={"Email"}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mb-3">
            <CustomButton
              color={"primary"}
              value={"Send Email"}
              variant={"contained"}
              onClick={onforgotHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
}
