import React from "react";
import ADrawer from "../components/ADrawer";
import ExtendteamComponent from "../components/ExtendteamComponent";
import { useLocation } from "react-router-dom";

export default function LeagueTeamextend() {
  const location = useLocation();
  const val = location.state;
  console.log("val");
  console.log(val);
  return (
    <>
      <div className="text-white flex items-center min-h-[100vh] w-full ">
        <ADrawer />
        <ExtendteamComponent />
      </div>
    </>
  );
}
