import { Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import Tab from "./Tab"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Adjusted width to be responsive
  maxWidth: 400, // Max width for larger screens
  bgcolor: "#082363",
  border: "",
  boxShadow: 80,
  overflow: "auto", // Changed to auto for responsive scrolling
  p: 2,
  color: "#fff",
  textAlign: "center",
};

export default function CustomModel({
  open,
  setOpen,
  buttonHandler,
  variant,
  innerText,
  headingText,
  termconditionText,
  scoringruleText,
  conditionText,
  conText,
  scoringText,
  privacyHandler,
  email
}) {
  const handleClose = () => setOpen(false);

  const onClickButtonHandler = () => {
    // buttonHandler();
    handleClose();
 
  };

  const navigate = useNavigate();

  const onSelectnHandler = () => {
    navigate("/signin");
  };

  if (variant === "email")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div style={{ cursor: "pointer" }}>
                <EmailIcon fontSize="large" sx={{ cursor: "pointer" }} />
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              An Email has been sent on {email} Verify your email!
            </Typography>
            <div className="mb-3 mt-6">
              <CustomButton
                color={"primary"}
                value={"OK"}
                variant={"contained"}
                onClick={onClickButtonHandler}
              />
            </div>
          </Box>
        </Modal>
      </div>
    );

  if (variant === "logout")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div style={{ cursor: "pointer" }}></div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to logout?
            </Typography>
            <div className="flex  justify-center items-center h-full mt-4">
              <div className="mb-3 ">
                <CustomButton
                  color={"white"}
                  value={"Cancel"}
                  variant={"outlined"}
                  onClick={onClickButtonHandler}
                />
              </div>
              <div className="mb-3 ml-2">
                <CustomButton
                  color={"primary"}
                  value={"Logout"}
                  variant={"contained"}
                  onClick={onSelectnHandler}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );

  if (variant === "popup")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <div style={{ cursor: "pointer" }}>
                <LoginIcon fontSize="large" sx={{ cursor: "pointer" }} />
                <span>
                  <CloseIcon
                    style={{ cursor: "pointer", float: "right" }}
                    onClick={onClickButtonHandler}
                  />
                </span>
              </div>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              To procced, you have to Signin first
            </Typography>
            <div className="mb-3 mt-6">
              <CustomButton
                color={"primary"}
                value={"Continue to Signin"}
                variant={"contained"}
                onClick={onSelectnHandler}
              />
            </div>
          </Box>
        </Modal>
      </div>
    );
  else if (variant === "text")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxWidth: "90%", maxHeight: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {headingText}
              </Typography>
              <span>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={onClickButtonHandler}
                />
              </span>
            </div>
            <hr />
            <div style={{ overflow: "auto" }}> {conditionText()}</div>
          </Box>
        </Modal>
      </div>
    );
  else if (variant === "scoring")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxWidth: "90%", maxHeight: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {headingText}
              </Typography>
              <span>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={onClickButtonHandler}
                />
              </span>
            </div>
            <hr />
            <div style={{ overflow: "auto" }}> <Tab/></div>
          </Box>
        </Modal>
      </div>
    );
  else if (variant === "privacy")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxWidth: "90%", maxHeight: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {headingText}
              </Typography>
              <span>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={onClickButtonHandler}
                />
              </span>
            </div>
            <hr />
            <div style={{ overflow: "auto" }}> {privacyHandler()}</div>
          </Box>
        </Modal>
      </div>
    );
  else if (variant === "submit")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(3px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxWidth: "90%", maxHeight: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {headingText}
              </Typography>
              <span>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={onClickButtonHandler}
                />
              </span>
            </div>
            <hr />
            <div style={{ overflow: "auto" }}> {conText()}</div>
            <div className="mb-3 mt-6">
              <CustomButton
                color={"primary"}
                value={"OK"}
                variant={"contained"}
                onClick={onClickButtonHandler}
              />
            </div>
          </Box>
        </Modal>
      </div>
    );
  else if (variant === "league")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(10px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxWidth: "90%", maxHeight: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {headingText}
              </Typography>
              <span className="cursor-pointer" onClick={onClickButtonHandler}>
                <CloseIcon />
              </span>
            </div>
            <hr />
            {termconditionText()}
          </Box>
        </Modal>
      </div>
    );
  else if (variant === "TermsandConditions")
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(10px)",
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxWidth: "90%", maxHeight: "90%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {headingText}
              </Typography>
              <span className="cursor-pointer" onClick={onClickButtonHandler}>
                <CloseIcon />
              </span>
            </div>
            <hr />
            {termconditionText()}
          </Box>
        </Modal>
      </div>
    );
}
