import { CircularProgress } from "@mui/material";
import React from "react";
import PlayerDetails from "./PlayerDetails";

export default function PositionGame({ title, refresh,game }) {
  
  // console.log("hhhhhhhhh", refresh);
  return (
    <>
      <div
        className="text-center text-xl pt-3 pb-3 "
        style={{
          background:
            "radial-gradient(circle, rgba(31,138,236,.7) 12%, rgba(15,47,100,.7) 85%)",
        }}
      >
        {refresh && refresh[0]?.position?.position}
      </div>
      {refresh &&
        refresh.map((item, index) => (
          <>
            <PlayerDetails item={item} game={game} />
            <hr />
          </>
        ))}
    </>
  );
}
