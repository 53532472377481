import React from "react";
import TextField from "@mui/material/TextField";

export default function CustomTextfield({
  value,
  placeholder,
  variant,
  type,
  size,
  inputProps,
  style,
  onChange,
  disabled,
  name
}) {
  return (
    <div>
      <TextField
        type={type}
        variant={variant}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        fullWidth
        sx={{ background: "white" }}
        style={style}
        InputProps={inputProps}
        onChange={(val) => onChange(val?.target?.value)}
        value={value}
        // sx={{ background: "green" }}
      />
    </div>
  );
}
