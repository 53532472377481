import React, { useState } from "react";
import CustomTextfield from "./CustomTextfield";
import { Button, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import photo from "../images/Ellipse 22.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EvaluateTable from "../components/EvaluateTable";
import { useAuth } from "../Context/AuthContext";
import { toast } from "react-toastify";

export default function Evaluateadmin({
  data,
  fpts1,
  setFpts1,
  rank1,
  setRank1,
  fpts2,
  setFpts2,
  rank2,
  setRank2,
  fpts3,
  setFpts3,
  rank3,
  setRank3,
  fpts4,
  setFpts4,
  rank4,
  setRank4,
  fpts5,
  setFpts5,
  rank5,
  setRank5,
  fpts6,
  setFpts6,
  rank6,
  setRank6,
  fpts7,
  setFpts7,
  rank7,
  setRank7,
  fpts8,
  setFpts8,
  rank8,
  setRank8,
  fpts9,
  setFpts9,
  rank9,
  setRank9,
  fpts10,
  setFpts10,
  rank10,
  setRank10,
  fpts11,
  setFpts11,
  rank11,
  setRank11,
  fpts12,
  setFpts12,
  rank12,
  setRank12,
  fpts13,
  setFpts13,
  rank13,
  setRank13,
  fpts14,
  setFpts14,
  rank14,
  setRank14,
  fpts15,
  setFpts15,
  rank15,
  setRank15,
  fpts16,
  setFpts16,
  rank16,
  setRank16,
  fpts17,
  setFpts17,
  rank17,
  setRank17,
  fpts18,
  setFpts18,
  rank18,
  setRank18,
  fpts19,
  setFpts19,
  rank19,
  setRank19,
  fpts20,
  setFpts20,
  rank20,
  setRank20,
  fpts21,
  setFpts21,
  rank21,
  setRank21,
  fpts22,
  setFpts22,
  rank22,
  setRank22,
  fpts23,
  setFpts23,
  rank23,
  setRank23,
  fpts24,
  setFpts24,
  rank24,
  setRank24,
}) {
  const buttonStyle = {
    padding: "7px 20px",
    borderRadius: "5px",
    margin: "4px",
  };

  const { token } = useAuth();

  const [savData, setSavData] = useState();

  const onCreateHandler = async () => {
    console.log("dddddddddd", data[0]?.id, fpts1, rank1);
    // try {
    // console.log("item",name);
    // console.log("response",abbreviation);
    // console.log("fghff:", val);
    const postdata = await fetch(
      "https://api.sportzcube.com/api/markPlayersAdmin",
      {
        method: "POST",
        body: JSON.stringify({
          player1_id: data[0]?.id,
          game_player_1_fpts: fpts1,
          game_player_1_rank: rank1,
          player2_id: data[1]?.id,
          game_player_2_fpts: fpts2,
          game_player_2_rank: rank2,
          player3_id: data[2]?.id,
          game_player_3_fpts: fpts3,
          game_player_3_rank: rank3,
          player4_id: data[3]?.id,
          game_player_4_fpts: fpts4,
          game_player_4_rank: rank4,
          player5_id: data[4]?.id,
          game_player_5_fpts: fpts5,
          game_player_5_rank: rank5,
          player6_id: data[5]?.id,
          game_player_6_fpts: fpts6,
          game_player_6_rank: rank6,
          player7_id: data[6]?.id,
          game_player_7_fpts: fpts7,
          game_player_7_rank: rank7,
          player8_id: data[7]?.id,
          game_player_8_fpts: fpts8,
          game_player_8_rank: rank8,
          player9_id: data[8]?.id,
          game_player_9_fpts: fpts9,
          game_player_9_rank: rank9,
          player10_id: data[9]?.id,
          game_player_10_fpts: fpts10,
          game_player_10_rank: rank10,
          player11_id: data[10]?.id,
          game_player_11_fpts: fpts11,
          game_player_11_rank: rank11,
          player12_id: data[11]?.id,
          game_player_12_fpts: fpts12,
          game_player_12_rank: rank12,
          player13_id: data[12]?.id,
          game_player_13_fpts: fpts13,
          game_player_13_rank: rank13,
          player14_id: data[13]?.id,
          game_player_14_fpts: fpts14,
          game_player_14_rank: rank14,
          player15_id: data[14]?.id,
          game_player_15_fpts: fpts15,
          game_player_15_rank: rank15,
          player16_id: data[15]?.id,
          game_player_16_fpts: fpts16,
          game_player_16_rank: rank16,
          player17_id: data[16]?.id,
          game_player_17_fpts: fpts17,
          game_player_17_rank: rank17,
          player18_id: data[17]?.id,
          game_player_18_fpts: fpts18,
          game_player_18_rank: rank18,
          player19_id: data[18]?.id,
          game_player_19_fpts: fpts19,
          game_player_19_rank: rank19,
          player20_id: data[19]?.id,
          game_player_20_fpts: fpts20,
          game_player_20_rank: rank20,
          player21_id: data[20]?.id,
          game_player_21_fpts: fpts21,
          game_player_21_rank: rank21,
          player22_id: data[21]?.id,
          game_player_22_fpts: fpts22,
          game_player_22_rank: rank22,
          player23_id: data[22]?.id,
          game_player_23_fpts: fpts23,
          game_player_23_rank: rank23,
          player24_id: data[23]?.id,
          game_player_24_fpts: fpts24,
          game_player_24_rank: rank24,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      }
    );

    const responseData = await postdata.json();
    setSavData(responseData);
    if (postdata.ok) {
      toast.success("Scores saved successfully");
    } else {
      toast.error(responseData.message);
    }
    console.log(" responseeeeeeeeee:", responseData);
    // } catch (err) {
    //   console.log("lll", err);
    // }
  };

  // console.log("gdfdgs", fpts1);

  return (
    <>
      <div
        className="p-3 text-white min-h-[100vh] w-full pr-4"
        style={{
          background: "#082362",
        }}
      >
        <div className="flex items-center justify-between w-full">
          <div className=" ">
            <div className="text-2xl pl-3">Creating Grid</div>
          </div>
          <div className=" flex items-center ">
            <div className=" text-white  ">
              <div className="flex ">
                <div className="flex flex-col  justify-center  ">
                  <div className=" pr-1 font-bold">
                    <img src={photo} alt="" />
                  </div>
                </div>
                <div className="p-2 ">
                  <div className="text-lg">Mike </div>
                  <div className="text-gray-400 text-sm">Administrator</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex items-center justify-between mt-10"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <Button
              color="white"
              variant="contained"
              style={buttonStyle}
              sx={{ background: "#2196FE" }}
              onClick={onCreateHandler}
            >
              Publish
            </Button>
          </div>
        </div>
        <hr className="mt-4" />
        <EvaluateTable
          data={data}
          fpts1={fpts1}
          setFpts1={setFpts1}
          rank1={rank1}
          setRank1={setRank1}
          fpts2={fpts2}
          setFpts2={setFpts2}
          rank2={rank2}
          setRank2={setRank2}
          fpts3={fpts3}
          setFpts3={setFpts3}
          rank3={rank3}
          setRank3={setRank3}
          fpts4={fpts4}
          setFpts4={setFpts4}
          rank4={rank4}
          setRank4={setRank4}
          fpts5={fpts5}
          setFpts5={setFpts5}
          rank5={rank5}
          setRank5={setRank5}
          fpts6={fpts6}
          setFpts6={setFpts6}
          rank6={rank6}
          setRank6={setRank6}
          fpts7={fpts7}
          setFpts7={setFpts7}
          rank7={rank7}
          setRank7={setRank7}
          fpts8={fpts8}
          setFpts8={setFpts8}
          rank8={rank8}
          setRank8={setRank8}
          fpts9={fpts9}
          setFpts9={setFpts9}
          rank9={rank9}
          setRank9={setRank9}
          fpts10={fpts10}
          setFpts10={setFpts10}
          rank10={rank10}
          setRank10={setRank10}
          fpts11={fpts11}
          setFpts11={setFpts11}
          rank11={rank11}
          setRank11={setRank11}
          fpts12={fpts12}
          setFpts12={setFpts12}
          rank12={rank12}
          setRank12={setRank12}
          fpts13={fpts13}
          setFpts13={setFpts13}
          rank13={rank13}
          setRank13={setRank13}
          fpts14={fpts14}
          setFpts14={setFpts14}
          rank14={rank14}
          setRank14={setRank14}
          fpts15={fpts15}
          setFpts15={setFpts15}
          rank15={rank15}
          setRank15={setRank15}
          fpts16={fpts16}
          setFpts16={setFpts16}
          rank16={rank16}
          setRank16={setRank16}
          fpts17={fpts17}
          setFpts17={setFpts17}
          rank17={rank17}
          setRank17={setRank17}
          fpts18={fpts18}
          setFpts18={setFpts18}
          rank18={rank18}
          setRank18={setRank18}
          fpts19={fpts19}
          setFpts19={setFpts19}
          rank19={rank19}
          setRank19={setRank19}
          fpts20={fpts20}
          setFpts20={setFpts20}
          rank20={rank20}
          setRank20={setRank20}
          fpts21={fpts21}
          setFpts21={setFpts21}
          rank21={rank21}
          setRank21={setRank21}
          fpts22={fpts22}
          setFpts22={setFpts22}
          rank22={rank22}
          setRank22={setRank22}
          fpts23={fpts23}
          setFpts23={setFpts23}
          rank23={rank23}
          setRank23={setRank23}
          fpts24={fpts24}
          setFpts24={setFpts24}
          rank24={rank24}
          setRank24={setRank24}
        />
      </div>
    </>
  );
}
