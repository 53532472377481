import React from "react";
import photo from "../images/Ellipse 20.png";
import vec from "../images/Vector.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Profile({topScorer, variant,list}) {
  if (variant == "leaderboard")
    return (
      <>
        <div className="border-2 border-blue-300 rounded-xl text-white bg-blue-950 px-4 py-4 mx-4  ">
          <div className="flex ">
            <div className="flex flex-col  justify-center  ">
              <div className=" pr-1 font-bold">
                <AccountCircleIcon />
              </div>
            </div>
            <div className="p-2 ">
              <div className="text-lg">
                {topScorer && topScorer?.current_user?.username}
              </div>
              <div className="text-gray-400 text-sm">
                {topScorer && topScorer?.current_user?.email}
              </div>
            </div>
          </div>
          <div className="flex pt-2 align-baseline">
            <div>
              Cumulative Cube:{" "}
              <span className="text-yellow-400">
                {topScorer &&
                  parseFloat(topScorer?.current_user?.cumulative_cube).toFixed(
                    2
                  )}
              </span>
            </div>
          </div>
          {topScorer && topScorer?.current_user?.games_played >= 10 ? null : (
            <div>Must have 10 complete SportzCubes to be ranked</div>
          )}
        </div>
      </>
    );
  else if (variant == "previouscube")
    return (
      <>
        <div className="border-2 border-blue-300 rounded-xl text-white bg-blue-950 px-4 py-4 mx-4  ">
          <div className="flex ">
            <div className="flex flex-col  justify-center  ">
              <div className=" pr-1 font-bold">
                <AccountCircleIcon />
              </div>
            </div>
            <div className="p-2 ">
              <div className="text-red-700">Current Cube Stats</div>
              <div className="text-lg">{list && list?.name}</div>
              <div className="text-gray-400 text-sm">{list && list?.email}</div>
            </div>
          </div>
          <div className="flex pt-2 align-baseline">
            {/* <div className="pl-2">
              <img src={vec} alt="" />
            </div>{" "} */}
            <div className="px-2">
              Current Cube:{" "}
              <span className="text-yellow-400">
                {" "}
                {list && parseFloat(list?.cube).toFixed(2)}
              </span>
            </div>
            <div className="pl-10">
              Square: {list && parseFloat(list?.user_square).toFixed(2)}
            </div>
            <div className="pl-10">
              Efficiency: {list && parseFloat(list?.efficiency).toFixed(2)}
            </div>
          </div>
        </div>
      </>
    );
}
