import React, { useEffect, useState } from "react";
import NavApp from "../components/NavApp";
import { Button } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import CustomTable from "../components/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import TeamTable from "../components/TeamTable";
import { useAuth } from "../Context/AuthContext";

export default function Team() {
  const buttonStyle = {
    padding: "2px 10px",
    borderRadius: "20px",
    background: "#1A43C0",
  };

  const { token } = useAuth();
  const navigate = useNavigate();
  const EventHandler = () => {
    navigate("/previousgames");
  };
  const EventchangeHandler = () => {
    navigate("/leaderboard");
  };
  const location = useLocation();
  const val = location?.state?.userId;
  const gam = location?.state?.gameId;

  console.log("idddddddddddddddddddddd", val);
  console.log("gameeeeeeeeeeeeeeeeeee", location?.state);

  //get api

  const [detail, setDetail] = React.useState([]);
  const [maxFpts, setMaxFpts] = useState("");
  const [eff, setEff] = useState("");
  const [sq, setSq] = useState("");
  const detailHandler = async () => {
    //  console.log("gameeeeeee");
    const leaddata = await fetch(
      "https://api.sportzcube.com/api/getGameUserDetails/" + val,
      {
        method: "POST",
        body: JSON.stringify({ game_id: gam }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const leadata = await leaddata.json();

    console.log("Detailsssssssssss", leadata);
    setDetail(leadata?.player_scores);
    setMaxFpts(leadata?.cube);
    setSq(leadata?.user_square);
    setEff(leadata?.efficiency);

    // console.log("hhhhhhhh", alldata.games);
  };
  console.log("scoreeee", detail);
  // console.log("maxxx",  parseFloat(maxFpts.toFixed(2)));
  // console.log("oooooo", leaderBoard);
  useEffect(() => {
    detailHandler();
  }, []);

  const clickHandler = () => {
    navigate("/headtohead");
  };

  return (
    <>
      <div
        className="text-white pb-4 min-h-[100vh]"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <NavApp />
        <div className="flex items-center justify-between p-5">
          <div className="text-lg"> My Today's Team</div>
        </div>
        <div className="px-4 py-3 mt-2">
          {detail && <TeamTable rows={detail} />}
        </div>
        <div className="flex flex-col md:flex-row mx-4 lg:items-center md:items-center">
          <div className="border-2 border-blue-300 w-full md:w-auto p-2 mb-2 md:mb-0 md:mr-1">
            <div className="text-4xl md:text-8xl">
              {sq && parseFloat(sq).toFixed(2)}
            </div>
            <div className="text-lg">Square</div>
          </div>
          <div className="border-2 border-blue-300 w-full md:w-auto p-2 mb-2 md:mb-0 md:mx-1">
            <div className="text-4xl md:text-8xl">
              {eff && parseFloat(eff).toFixed(2)}
            </div>
            <div className="text-lg">Efficiency</div>
          </div>
          <div className="border-2 border-blue-300 w-full md:w-auto p-2 mx-1 bg-yellow-400 text-black">
            <div className="text-4xl md:text-8xl">
              {maxFpts && parseFloat(maxFpts).toFixed(2)}
            </div>
            <div className="text-lg">Cube</div>
          </div>
        </div>

        <div className="flex">
          <div className="text-center items-center mt-6 justify-evenly ml-8">
            <Button
              color="white"
              variant="outlined"
              onClick={EventchangeHandler}
              style={{ padding: "10px 20px", borderRadius: "40px" }}
            >
              View LeaderBoard
            </Button>
          </div>
          <div className="text-center items-center mt-6 ml-3">
            <Button
              color="white"
              variant="outlined"
              onClick={EventHandler}
              style={{ padding: "10px 20px", borderRadius: "40px" }}
            >
              Cube History
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
