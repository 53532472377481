import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import HeadtoHeadComponent from "./HeadtoHeadComponent";
import { Navigate, useNavigate } from "react-router-dom";

function createData(id, name, calories, fat, carbs, protein, rows) {
  return {
    id,
    name,
    calories,
    fat,
  };
}

const rows = [
  createData(1, "Cupcake", 305, 34, 50),
  createData(2, "Donut", 452, 34, 50),
  createData(3, "Eclair", 262, 34, 50),
  createData(4, "Frozen yoghurt", 159, 34, 50),
  createData(5, "Gingerbread", 356, 34, 50),
  createData(6, "Honeycomb", 408, 34, 50),
  createData(7, "Ice cream sandwich", 237, 34, 50),
  createData(8, "Jelly Bean", 375, 34, 50),
  createData(9, "KitKat", 518, 34, 50),
  createData(10, "Lollipop", 392, 34, 50),
  createData(11, "Marshmallow", 318, 34, 50),
  createData(12, "Nougat", 360, 34, 50),
  createData(13, "Oreo", 437, 34, 50),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Rank",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Square",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Efficiency",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Cube",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: "16px" }}
            style={{ color: "white" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CustomTable({ rows, idd, searchText }) {
  console.log("rows:", rows);
  console.log("vallllll:", idd);
  rows?.sort((b, a) => a.cube - b.cube);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("player");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const navigate = useNavigate();

  const handleClick = (event, user_id) => {
    console.log("popopo", idd);
    navigate("/teams", {
      replace: true,
      state: {
        userId: user_id,
        gameId: idd,
      },
    });

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (user_id) => selected.indexOf(user_id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  var visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const [newRows, setNewRows] = React.useState(visibleRows);

    React.useEffect(() => {
      setNewRows(visibleRows);
    }, [visibleRows]);
  
  const currentUser = localStorage.getItem("newEmail");
  console.log(currentUser);

  React.useEffect(() => {
    const filteredRows = rows?.filter((row) =>
      row?.username?.includes(searchText)
    );
    setNewRows(filteredRows);
  }, [searchText, rows]);

  function roundToTwoDecimals(num) {
    // Check if the number is an integer
    if (Number.isInteger(num)) {
      return num;
    } else {
      return parseFloat(num.toFixed(2));
    }
  }

  var userName=  localStorage.getItem('username')

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, bgcolor: "transparent" }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{ color: "white" }}
            // sx={{ minWidth: 150 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {searchText !== "" &&
                rows
                  .slice()
                  .filter(
                    (item) =>
                      item?.username === "Jonnie Aces" ||
                      item?.username === `${userName}`
                  )
                  .sort((a, b) => b.average_cube - a.average_cube)
                  .map((row, index) => (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.user_id)}
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        // id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ paddingLeft: "19px" }}
                        style={{ color: "white" }}
                      >
                        <HeadtoHeadComponent
                          position={index + 1}
                          variant={"headtohead"}
                          row={row}
                        />
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.user_square).toFixed(2)}
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.efficiency).toFixed(2)}
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.cube).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
              {searchText !== "" &&
                newRows
                  .filter((item) => item?.username !== `${userName}`)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.user_id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return currentUser !== row.email ? (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.user_id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ paddingLeft: "19px" }}
                          style={{ color: "white" }}
                        >
                          <HeadtoHeadComponent
                            position={index + 1}
                            variant={"headtohead"}
                            row={row}
                          />
                        </TableCell>
                        <TableCell align="right" style={{ color: "white" }}>
                          {row && parseFloat(row?.user_square).toFixed(2)}
                        </TableCell>
                        <TableCell align="right" style={{ color: "white" }}>
                          {row && parseFloat(row?.efficiency).toFixed(2)}
                        </TableCell>
                        <TableCell align="right" style={{ color: "white" }}>
                          {row && parseFloat(row?.cube).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.user_id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        className="border-red-500 border-4"
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{ paddingLeft: "19px" }}
                          style={{ color: "white" }}
                        >
                          <HeadtoHeadComponent
                            position={index + 1}
                            variant={"headtohead"}
                            row={row}
                          />
                        </TableCell>
                        <TableCell align="right" style={{ color: "white" }}>
                          {row && parseFloat(row?.user_square).toFixed(2)}
                        </TableCell>
                        <TableCell align="right" style={{ color: "white" }}>
                          {row && parseFloat(row?.efficiency).toFixed(2)}
                        </TableCell>
                        <TableCell align="right" style={{ color: "white" }}>
                          {row && parseFloat(row?.cube).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {searchText == "" &&
                newRows.map((row, index) => {
                  const isItemSelected = isSelected(row.user_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return currentUser !== row.email ? (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.user_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ paddingLeft: "19px" }}
                        style={{ color: "white" }}
                      >
                        <HeadtoHeadComponent
                          position={index + 1}
                          variant={"headtohead"}
                          row={row}
                        />
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.user_square).toFixed(2)}
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.efficiency).toFixed(2)}
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.cube).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.user_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      className="border-red-500 border-4"
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ paddingLeft: "19px" }}
                        style={{ color: "white" }}
                      >
                        <HeadtoHeadComponent
                          position={index + 1}
                          variant={"headtohead"}
                          row={row}
                        />
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.user_square).toFixed(2)}
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.efficiency).toFixed(2)}
                      </TableCell>
                      <TableCell align="right" style={{ color: "white" }}>
                        {row && parseFloat(row?.cube).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 35]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ color: "white" }}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
