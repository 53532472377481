import React, { useEffect, useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Time from "./Time";
import { useAuth } from "../Context/AuthContext";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DateTime({ game, setSecondsRemaining }) {
  const { username, token } = useAuth();

  const handle = () => {
    return username;
  };

  function formatTo12Hour(date) {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + strMinutes + ' ' + ampm;
}

  const getTime = (date, time) => {
    try {
        // Ensure that the date is in YYYY-MM-DD format
        const formattedDate = dayjs(date).format("YYYY-MM-DD");

        // Combine date and time into an ISO 8601 string with the EST timezone offset
        const combinedDateTime = `${formattedDate}T${time}`; // EST during daylight saving time

        console.log("Combined DateTime (EST):", combinedDateTime);

        // Parse combined date and time as EST
        const estDateTime = dayjs.tz(combinedDateTime);

        console.log("Parsed EST DateTime Object:", estDateTime.toString());

        const dd = new Date(estDateTime.toString())

        const adjustedTime = formatTo12Hour(dd);

        // Format the adjusted date and time
        // const adjustedTime = dd.format("h:mm A");

        console.log("Adjusted Time (EST):", adjustedTime);

        return adjustedTime;
    } catch (error) {
        console.error("Error parsing time:", error);
        return "Invalid Time";
    }
};

const getDate = (date, time) => {
  try {
      // Parse the input date to MM/DD/YYYY format
      const formattedDate = dayjs(date, "YYYY/MM/DD").format("MM/DD/YYYY");

      // Combine date and time into a single string
      const combinedDateTime = `${formattedDate} ${time}`;

      console.log("Combined DateTime:", combinedDateTime);

      // Parse the combined date and time with EST timezone
      const estDateTime = dayjs.tz(combinedDateTime, "MM/DD/YYYY HH:mm", "America/New_York");

      console.log("EST DateTime Object:", estDateTime.toString());

      // Format the date to MM/DD/YYYY
      const adjustedDate = estDateTime.format("MM/DD/YYYY");

      return adjustedDate;
  } catch (error) {
      console.error("Error parsing date:", error);
      return "Invalid Date";
  }
};

  return (
    <>
      <div className="mt-6">
        <div className="border-2 border-blue-300 rounded-xl text-white bg-blue-950 px-4 py-6 md:px-9 md:py-9 mx-5 md:mx-4">
          <div className="flex justify-between">
            <div>
              {token ? (
                <div>
                  Welcome{" "}
                  <span className="text-xl text-green-300">
                    {handle && handle()}
                  </span>{" "}
                  <WavingHandIcon />
                </div>
              ) : (
                <div>Log in to play!</div>
              )}
            </div>
            <div className="flex items-center">
              <CalendarTodayIcon />
              <span className="ml-2">
                {new Date().getMonth() +
                  1 +
                  "/" +
                  new Date().getDate() +
                  "/" +
                  new Date().getFullYear()}
              </span>
            </div>
          </div>
          <div className="text-white mt-4">
            <h1 className="text-2xl md:text-3xl mt-4 mb-2">Today's Cube</h1>
            <h6 className="text-gray-400 mb-4">
              Select one player from each position to create a SportzCube
            </h6>
            <h6 className="text-lg">{game?.league?.name}</h6>
          </div>

          <hr className="mt-6 text-blue-400" />

          <div className="mt-3 text-center ">
            <Time
              targetDate={game?.cutoff_date}
              targetTime={game?.cutoff_time}
              setSecondsRemaining={setSecondsRemaining}
            />
            <h1 className="mt-3">Time Remaining</h1>
          </div>

          <div className="flex md:flex-row items-center justify-evenly px-4 md:px-12 pt-4 md:pt-6">
            <div>
              <h4 className="text-lg">
                {game?.cutoff_date &&
                  getDate(
                    game?.cutoff_date,
                    game?.cutoff_time
                  )}
              </h4>
              <h4 className="text-sm text-gray-400">Cut Off Date</h4>
            </div>
            <div className="mt-0 md:mt-0 text-lg">
              <p>
                {game.cutoff_time &&
                  getTime(game?.cutoff_date, game?.cutoff_time)} (EST)
              </p>
              <p className="text-sm  text-gray-400">Cut Off Time</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
