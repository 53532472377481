import * as React from "react";
import NavApp from "../components/NavApp";
import DateTime from "../components/DateTime";
import PositionGame from "../components/PositionGame";
import { Button } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import CustomModel from "../components/CustomModel";
import CustomTextfield from "../components/CustomTextfield";
import { useAuth } from "../Context/AuthContext";
import { useEffect } from "react";

const conditionText = () => (
  <>
    <div className="text-xl align-baseline">
    <ol style={{ fontSize: "1.1rem" }}>
          {" "}
          <li className="mt-4 mb-4 text-justify">
            • A SportzCube is composed of 6 Position Squares. Each Position
            Square consists of 4 possible player choices.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • Select one player for each Position Square that you believe will
            achieve the greatest amount of fantasy points of the 4 available
            choices by the defined cutoff date and time.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • You will have one entry and only complete SportzCubes will be
            considered.{" "}
          </li>{" "}
          <li className="mt-2 mb-4 text-justify">
            • A complete SportzCube is defined as one selection from each of the
            6 Positional Squares.{" "}
          </li>{" "}
          <br />
          <li className="mt-2 mb-4 text-justify">That’s it—Good luck! </li>{" "}
        </ol>
    </div>
  </>
);

export default function HomePage() {
  const buttonStyle = {
    padding: "10px 20px",
    borderRadius: "40px",
  };
  const { token } = useAuth();
  const navigate = useNavigate();

  const onSigninHandler = () => {
    navigate("/modal");
  };

  const [open, setOpen] = React.useState(true);
  const AddLeagueHandler = () => {
    setOpen(true);
  };

  const [newOpen, newsetOpen] = React.useState(false);
  const LeagueHandler = () => {
    if (token) return navigate("/landingpage");
    newsetOpen(true);
  };

  const LeagueKiHandler = () => {
    navigate("/leaderboard");
    newsetOpen(true);
  };

  const [showPositionGame, setShowPositionGame] = React.useState(false);

  const handleExpandClick = () => {
    setShowPositionGame(!showPositionGame);
  };

  // main api for user
  const [cdate, setCdate] = React.useState("");
  const [showing, setShowing] = React.useState([]);
  const [game, setGame] = React.useState("");
  const [player, setPlayer] = React.useState("");
  const GShowHandler = async () => {
    //  console.log("gameeeeeee");
    const odata = await fetch("https://api.sportzcube.com/api/index", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const alldata = await odata.json();
    setShowing(alldata);
    setGame(alldata?.game);
    setPlayer(alldata?.game_players);
    setCdate(alldata?.game?.cutoff_date);
    console.log("gameeeeeeeeeeeeeshowinggggg", alldata);
    console.log("howinggggg", alldata?.game);
    console.log("ggggg", player);

    // console.log("hhhhhhhh", alldata.games);
  };

  const currentDate = new Date();

  const isAfterCutoff = currentDate > cdate;
  // console.log("ffffffffff",cdate);

  useEffect(() => {
    GShowHandler();
  }, []);

  const [secondsRemaining, setSecondsRemaining] = React.useState(0);

  // console.log(handle()); // Output: example

  return (
    <>
      <CustomModel
        buttonHide={false}
        open={open}
        variant={"text"}
        setOpen={setOpen}
        // innerText={innerText}
        conditionText={conditionText}
        headingText={"How to play SPORTZCUBE"}
      />

      <CustomModel
        buttonHide={false}
        open={newOpen}
        variant={"popup"}
        setOpen={newsetOpen}
      />

      <div
        className="pb-8 min-h-[100vh]"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <NavApp />
        {game && (
          <DateTime game={game} setSecondsRemaining={setSecondsRemaining} />
        )}
        <div className="  text-white bg-blue-950 p-4 m-4  border-2 border-blue-300 rounded-xl ">
          <div className="content-between flex justify-between">
            <div className="text-lg pb-2 ">Overview</div>
            <div className="cursor-pointer">
              {/* Toggle icon */}
              {showPositionGame ? (
                <ExpandLessIcon onClick={handleExpandClick} />
              ) : (
                <ExpandMoreIcon onClick={handleExpandClick} />
              )}
            </div>
          </div>
          {showPositionGame && (
            <div>
              {/* {console.log("gggggg", showing?.game_players)} */}
              <PositionGame
                // title={showing && showing?.game_players[0]?.position?.position}
                refresh={
                  showing?.game_players &&
                  showing?.game_players?.filter(
                    (player) =>
                      player.position.position ===
                      showing?.game_players[0].position.position
                  )
                }
                game={game}
              />
              <PositionGame
                refresh={
                  showing?.game_players &&
                  showing?.game_players?.filter(
                    (player) =>
                      player.position.position ===
                      showing?.game_players[4].position.position
                  )
                }
                game={game}
                // title={showing?.game_players[1]?.position?.position}
              />
              <PositionGame
                refresh={
                  showing?.game_players &&
                  showing?.game_players?.filter(
                    (player) =>
                      player.position.position ===
                      showing?.game_players[8].position.position
                  )
                }
                game={game}
                // title={showing?.game_players[2]?.position?.position}
              />
              <PositionGame
                refresh={
                  showing?.game_players &&
                  showing?.game_players?.filter(
                    (player) =>
                      player.position.position ===
                      showing?.game_players[12].position.position
                  )
                }
                game={game}
                // title={showing?.game_players[3]?.position?.position}
              />
              <PositionGame
                refresh={
                  showing?.game_players &&
                  showing?.game_players?.filter(
                    (player) =>
                      player.position.position ===
                      showing?.game_players[16].position.position
                  )
                }
                game={game}
                // title={showing?.game_players[4]?.position?.position}
              />
              <PositionGame
                refresh={
                  showing?.game_players &&
                  showing?.game_players?.filter(
                    (player) =>
                      player.position.position ===
                      showing?.game_players[20].position.position
                  )
                }
                game={game}
                // title={showing?.game_players[5]?.position?.position}
              />
            </div>
          )}
        </div>
        {/* {console.log('sdasdsddad', secondsRemaining)} */}
        <div className="text-center items-center mt-6 cursor-pointer">
          {secondsRemaining == 0 ? (
            <Button
              color="white"
              onClick={LeagueKiHandler}
              variant="outlined"
              style={buttonStyle}
            >
              Show Leaderboard
            </Button>
          ) : (
            <Button
              color="white"
              onClick={LeagueHandler}
              variant="outlined"
              style={buttonStyle}
            >
              Start Selection
            </Button>
          )}
        </div>
        <div />
      </div>
    </>
  );
}
