import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAuth } from "../Context/AuthContext";
import CustomTextfield from "./CustomTextfield";

function createData(id, name, email, dob, djoined, actions) {
  return { id, name, email, dob, djoined, actions };
}

const rows = [
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12, 2000",
    "Dec12, 2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12,2000",
    "Dec12,2000",
    "-"
  ),
  createData(
    "@14666",
    "Mike Adams",
    "Adam9984@gmail.com",
    "Dec12, 2000",
    "Dec12,2000",
    "-"
  ),
];

export default function EvaluateTable({
  data,
  fpts1,
  setFpts1,
  rank1,
  setRank1,
  fpts2,
  setFpts2,
  rank2,
  setRank2,
  fpts3,
  setFpts3,
  rank3,
  setRank3,
  fpts4,
  setFpts4,
  rank4,
  setRank4,
  fpts5,
  setFpts5,
  rank5,
  setRank5,
  fpts6,
  setFpts6,
  rank6,
  setRank6,
  fpts7,
  setFpts7,
  rank7,
  setRank7,
  fpts8,
  setFpts8,
  rank8,
  setRank8,
  fpts9,
  setFpts9,
  rank9,
  setRank9,
  fpts10,
  setFpts10,
  rank10,
  setRank10,
  fpts11,
  setFpts11,
  rank11,
  setRank11,
  fpts12,
  setFpts12,
  rank12,
  setRank12,
  fpts13,
  setFpts13,
  rank13,
  setRank13,
  fpts14,
  setFpts14,
  rank14,
  setRank14,
  fpts15,
  setFpts15,
  rank15,
  setRank15,
  fpts16,
  setFpts16,
  rank16,
  setRank16,
  fpts17,
  setFpts17,
  rank17,
  setRank17,
  fpts18,
  setFpts18,
  rank18,
  setRank18,
  fpts19,
  setFpts19,
  rank19,
  setRank19,
  fpts20,
  setFpts20,
  rank20,
  setRank20,
  fpts21,
  setFpts21,
  rank21,
  setRank21,
  fpts22,
  setFpts22,
  rank22,
  setRank22,
  fpts23,
  setFpts23,
  rank23,
  setRank23,
  fpts24,
  setFpts24,
  rank24,
  setRank24,
}) {
  // const { token } = useAuth();
  // console.log("dataaaa", data);
  const getDate = (date) => {
    const newDate = new Date(date);
    // console.log(
    //   "lll",
    //   newDate.getDate() +
    //     " - " +
    //     newDate.getMonth() +
    //     " - " +
    //     newDate.getFullYear()
    // );
    return (
      (newDate.getMonth()+1) +
      "/" +
      newDate.getDate() +
      "/" +
      newDate.getFullYear()
    );
  };

const getTime = (time) => {
  const newTime = new Date(time);
  if (isNaN(newTime)) {
    throw new Error("Invalid date format");
  }

  let hours = newTime.getHours();
  const minutes = newTime.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutesStr + " " + ampm;
};
  return (
    <TableContainer
      component={Paper}
      sx={{ color: "white", bgcolor: "transparent" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ color: "white", background: "#4775FF" }}>
          <TableRow>
            <TableCell style={{ color: "white" }}>Player Name</TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Position
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Team
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Match
            </TableCell>
            <TableCell align="right" style={{ color: "white" }}>
              Date Time
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              FPTS
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Rank
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Square Points
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {/* {console.log("ghhghghghhg",data)} */}
          {data.map((item, index) => (
            <TableRow
              key={item.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" style={{ color: "white" }}>
                {item?.player_name}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {item?.position?.abbreviation}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {item?.team?.abbreviation}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {item?.match?.home_team?.abbreviation + " vs "
                + item?.match?.away_team?.abbreviation}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {item?.match?.start_date &&
                  item?.match?.start_time &&
                  `${
                    getDate(item?.match?.start_date) +
                    " / " +
                    getTime(item?.match?.start_time)
                  }`}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {/* {console.log("fpts1", fpts1)} */}
                {index == 0 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts1}
                      onChange={setFpts1}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 1 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts2}
                      onChange={setFpts2}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 2 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts3}
                      onChange={setFpts3}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 3 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts4}
                      onChange={setFpts4}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 4 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts5}
                      onChange={setFpts5}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 5 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts6}
                      onChange={setFpts6}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 6 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts7}
                      onChange={setFpts7}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 7 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts8}
                      onChange={setFpts8}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 8 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts9}
                      onChange={setFpts9}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 9 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts10}
                      onChange={setFpts10}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 10 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts11}
                      onChange={setFpts11}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 11 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts12}
                      onChange={setFpts12}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 12 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts13}
                      onChange={setFpts13}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 13 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts14}
                      onChange={setFpts14}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 14 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts15}
                      onChange={setFpts15}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}{" "}
                {index == 15 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts16}
                      onChange={setFpts16}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 16 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts17}
                      onChange={setFpts17}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 17 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts18}
                      onChange={setFpts18}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 18 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts19}
                      onChange={setFpts19}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 19 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts20}
                      onChange={setFpts20}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}{" "}
                {index == 20 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts21}
                      onChange={setFpts21}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 21 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts22}
                      onChange={setFpts22}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 22 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts23}
                      onChange={setFpts23}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index == 23 && (
                  <div className=" w-24 ">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={fpts24}
                      onChange={setFpts24}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {index === 0 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank1}
                      onChange={setRank1}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 1 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank2}
                      onChange={setRank2}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 2 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank3}
                      onChange={setRank3}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 3 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank4}
                      onChange={setRank4}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 4 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank5}
                      onChange={setRank5}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 5 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank6}
                      onChange={setRank6}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 6 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank7}
                      onChange={setRank7}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 7 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank8}
                      onChange={setRank8}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 8 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank9}
                      onChange={setRank9}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 9 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank10}
                      onChange={setRank10}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 10 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank11}
                      onChange={setRank11}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 11 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank12}
                      onChange={setRank12}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 12 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank13}
                      onChange={setRank13}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 13 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank14}
                      onChange={setRank14}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 14 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank15}
                      onChange={setRank15}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 15 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank16}
                      onChange={setRank16}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 16 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank17}
                      onChange={setRank17}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 17 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank18}
                      onChange={setRank18}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 18 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank19}
                      onChange={setRank19}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 19 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank20}
                      onChange={setRank20}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 20 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank21}
                      onChange={setRank21}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 21 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank22}
                      onChange={setRank22}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 22 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank23}
                      onChange={setRank23}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                {index === 23 && (
                  <div className=" w-24">
                    <CustomTextfield
                      type={"text"}
                      size={"small"}
                      variant={"outlined"}
                      value={rank24}
                      onChange={setRank24}
                      placeholder={""}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                {item && item?.square}
              </TableCell>
              <TableCell align="center" style={{ color: "white" }}>
                -
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
