import React, { useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CustomModel from "./CustomModel";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CustomTextfield from "./CustomTextfield";
import CustomDropdown from "./CustomDropdown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Api } from "@mui/icons-material";
import { useAuth } from "../Context/AuthContext";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function AdminTeamComponent1({
  variant,
  responseData,
  gameidd,
  leagId,
  fresh,
  item,
  ggg,
  team1,
  setTeam1,
  team2,
  setTeam2,
  matches,
  del,
}) {
  const [open, setOpen] = React.useState(false);
  const AddFixtureHandler = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const { token } = useAuth();

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  console.log("infooooo", item?.away_team);

  // console.log("adminnnnnn", responseData);
  console.log("iddddd", gameidd);
  console.log("leaguiddddd", leagId);

  // post create match Api

  // const [team1, setTeam1] = useState("");
  // const [team2, setTeam2] = useState("");
  const [date, setDate] = useState(dayjs());

  const [time, setTime] = useState(dayjs("2022-04-17T15:30"));

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };
  // const [leaguid, setLeagueId] = useState("");
  const onCreateMatchHandler = async () => {
    // try {
    // console.log("item",name);
    // console.log("response",abbreviation);
    // console.log("fghff:", val);
    console.log("teammm", team1);
    console.log("teammm2", team2);
    console.log("date", date);
    console.log("time", time);

    const postdata = await fetch(
      "https://api.sportzcube.com/api/createMatch/" + gameidd,
      {
        method: "POST",
        body: JSON.stringify({
          team_1: team1,
          team_2: team2,
          start_date: date,
          start_time: time,
          league_id: leagId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      }
    );
    // console.log("ttttttt", await data.text());

    // const jsonData = ;

    //   const d = await data.json();
    //   console.log("ttttttt1", d);
    // };

    const responseData = await postdata.json();
    handleClose();

    if (postdata.ok) {
      console.log(responseData);
      fresh();
      ggg();
      console.log(postdata.ok);
      toast.success(responseData.message);
    } else {
      console.error(responseData.message);
      console.error(responseData.error);
      toast.error(responseData.message);
    }

    console.log("Create Match response:", responseData);
    // } catch (err) {
    //   console.log("lll", err);
    // }
  };

  //delete match api

  // console.log("hamza", gameidd)

  const getDate = (date) => {
    const newDate = new Date(date);
    //  console.log(
    //    "lll",
    //    newDate.getDate() +
    //      " - " +
    //      newDate.getMonth() +
    //      " - " +
    //      newDate.getFullYear()
    //  );
    return (
      newDate.getMonth() +
      1 +
      "/" +
      newDate.getDate() +
      "/" +
      newDate.getFullYear()
    );
  };

  const getTime = (time) => {
    const newTime = new Date(time);
    if (isNaN(newTime)) {
      throw new Error("Invalid date format");
    }

    let hours = newTime.getHours();
    const minutes = newTime.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    return hours + ":" + minutesStr + " " + ampm;
  };

  // console.log("jjjjjjjj",item);

  const termconditionText = () => (
    <>
      {/* {console.log("dfdfdddf,", responseData?.teams)} */}
      <hr />
      <div className=" mt-4 align-baseline">
        <CustomDropdown
          name={"Select team 1"}
          type={"true"}
          team={responseData?.teams}
          label={"Select Team1"}
          teamss={setTeam1}
        />
      </div>

      <div className=" mt-4 align-baseline">
        <CustomDropdown
          name={"Select team 2"}
          type={"true"}
          team={responseData?.teams}
          label={"Select Team2"}
          teamss={setTeam2}
        />
      </div>
      <div className=" mt-4 align-baseline">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            className="bg-white w-full h-[8.5vh]"
            value={date}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </div>
      <div className=" mt-2 align-baseline">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["TimePicker"]}>
            <TimePicker
              value={time}
              onChange={(newValue) => setTime(newValue)}
              className="w-full"
              fontSize="small"
              label="Select time "
              style={{ background: "white" }}
              sx={{
                background: "white",
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <div className="mt-4 align-baseline">
        <Button
          color="white"
          variant="outlined"
          onClick={handleClose}
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
        >
          Cancel
        </Button>
        <Button
          color="white"
          variant="contained"
          style={{ padding: "7px 20px", borderRadius: "5px", margin: "4px" }}
          sx={{ background: "#2196FE" }}
          onClick={onCreateMatchHandler}
        >
          Create
        </Button>
      </div>
    </>
  );
  // console.log("TTTTTTTTT", );
  if (variant === "text")
    return (
      <>
        <div
          className="border-2 cursor-pointer border-blue-300 rounded-lg text-white bg-[#10368D] px-6 pt-6 py-8  ml-4 mt-4 w-full"
          v
        >
          <div className="bg-[#4775FF] px-2 py-1 rounded-lg text-center ">
            Fixture 1 <DeleteForeverIcon onClick={() => del(item?.id)} />{" "}
          </div>
          <div className="text-[#FAFF00] text-4xl font-bold flex mt-3 items-center justify-evenly text-center px-6 ">
            {" "}
            <div>{item?.away_team?.abbreviation}</div>{" "}
            <div className="text-white text-4xl font-thin"> vs </div>{" "}
            <div>{item?.home_team?.abbreviation}</div>
          </div>
          <div className="flex pt-4 justify-between content-between mt-3">
            <div className="">
              {" "}
              <CalendarTodayIcon />
              {item?.start_date && getDate(item?.start_date)}
            </div>
            <div className="pt- pl-2">
              {" "}
              <TimelapseIcon /> {item?.start_time && getTime(item?.start_time)}
            </div>
          </div>
        </div>
      </>
    );
  else if (variant === "new")
    return (
      <>
        <CustomModel
          buttonHide={true}
          open={open}
          variant={"league"}
          setOpen={setOpen}
          style={{ width: "80vh" }}
          // innerText={innerText}
          termconditionText={termconditionText}
          headingText={"Create New Fixture"}
        />
        <div
          onClick={AddFixtureHandler}
          className="border-2 border-blue-300 rounded-lg cursor-pointer text-white bg-[#10368D] px-6 pt-6 py-[13.8vh]  ml-4 mt-4 w-full"
        >
          <div className="flex  justify-evenly items-center pt-6">
            {" "}
            <AddBoxIcon fontSize="large" />
          </div>
          <div className="justify-evenly items-center text-center pt-2">
            Add New Fixture
          </div>
        </div>
      </>
    );
}
