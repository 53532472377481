import React, { useState } from "react";
import CustomTextfield from "./CustomTextfield";
import { Button, InputAdornment } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { toast } from "react-toastify";
import { useAuth } from "../Context/AuthContext";

export default function AdminSettings() {
  const buttonStyle = {
    padding: "7px 10px",
    borderRadius: "5px",
    margin: "4px",
  };

  const { token } = useAuth();

  // change password api

  const [password, setPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [retype_password, setRetypePassword] = useState("");

  const onChangePasswordHandler = async () => {
    try {
      const postdata = await fetch(
        "https://api.sportzcube.com/api/changeAdminPassword",
        {
          method: "POST",
          body: JSON.stringify({ password, new_password, retype_password }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );
      // console.log("ttttttt", await data.text());

      // const jsonData = ;

      //   const d = await data.json();
      //   console.log("ttttttt1", d);
      // };
      console.log(postdata);
      const responseData = await postdata.json();
      // setPassword(password);
      // setNewPassword(new_password);
      // setRetypePassword(retype_password);

      if (postdata.ok) {
        console.log(responseData);
        console.log(postdata.ok);
        toast.success(responseData.message);
      } else {
        console.error(responseData.message);
        console.error(responseData.error);
        toast.error(responseData.message);
      }

      console.log("Password saved: ", responseData);
    } catch (err) {
      console.log("lll", err);
    }
  };

  return (
    <>
      <div className=" border-2 border-blue-300 rounded-lg text-white bg-[#10368D] pl-6 pr-2 pt-6 py-8  mx-4 mt-12 w-[125vh]">
        <div>Change Password</div>
        <div className="flex">
          <div className="mb-4 mt-8 w-full">
            <CustomTextfield
              type={"password"}
              size={"small"}
              variant={"filled"}
              value={password}
              onChange={setPassword}
              placeholder={"Enter Password Here"}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mb-4 mt-8 mx-4 w-full">
            <CustomTextfield
              type={"password"}
              size={"small"}
              variant={"filled"}
              value={new_password}
              onChange={setNewPassword}
              placeholder={"New Password"}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="mb-4 w-[57vh] ">
          <CustomTextfield
            type={"password"}
            size={"small"}
            variant={"filled"}
            value={retype_password}
            onChange={setRetypePassword}
            placeholder={"Re-enterPassword"}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="flex ">
          <div>
            <Button
              color="white"
              variant="contained"
              style={buttonStyle}
              sx={{ background: "#2196FE" }}
              onClick={onChangePasswordHandler}
            >
              Save Password
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
