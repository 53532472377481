import React, { useEffect, useState } from "react";
import DateTime from "../components/DateTime";
import NavApp from "../components/NavApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Center from "../components/Center";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import CustomModel from "../components/CustomModel";

export default function LandingPage() {
  const buttonStyle = {
    padding: "10px 20px",
    borderRadius: "40px",
  };

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [selectedPlayers, setSelectedPlayers] = useState({
    p1: {},
    p2: {},
    p3: {},
    p4: {},
    p5: {},
    p6: {},
  });
  console.log("selected index", selectedPlayers);
  const handleSelectedPlayers = (type, playerData) => {
    setSelectedPlayers({
      ...selectedPlayers,
      [type]: playerData,
    });
  };

  const [page, setPage] = useState(0);

  const navigate = useNavigate();
  const { token } = useAuth();

  const onSelectNextHandler = () => {
    if (page == 0) {
      if (selectedPlayers?.p1?.id) {
        if (page < 5) {
          setPage(page + 1);
        } else {
          navigate("/");
          // getDataHandler();
        }
      } else {
        alert("Please select a card before proceeding to the next page.");
      }
    }
    if (page == 1) {
      console.log("111111", selectedPlayers);
      if (selectedPlayers?.p2?.id) {
        if (page < 5) {
          setPage(page + 1);
        } else {
          navigate("/");
        }
      } else {
        alert("Please select a card before proceeding to the next page.");
      }
    }
    if (page == 2) {
      if (selectedPlayers?.p3?.id) {
        if (page < 5) {
          setPage(page + 1);
        } else {
          navigate("/");
        }
      } else {
        alert("Please select a card before proceeding to the next page.");
      }
    }
    if (page == 3) {
      if (selectedPlayers?.p4?.id) {
        if (page < 5) {
          setPage(page + 1);
        } else {
          navigate("/");
        }
      } else {
        alert("Please select a card before proceeding to the next page.");
      }
    }
    if (page == 4) {
      if (selectedPlayers?.p5?.id) {
        if (page < 5) {
          setPage(page + 1);
        } else {
          navigate("/");
        }
      } else {
        alert("Please select a card before proceeding to the next page.");
      }
    }
    if (page == 5) {
      if (selectedPlayers?.p6?.id) {
        navigate("/");
      } else {
        return alert("Please select a card before submiting the team");
      }
    }
  };

  const onSelectPreviousHandler = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [data5, setData5] = useState("");
  const [data6, setData6] = useState("");

  const [showing, setShowing] = React.useState([]);
  const [game, setGame] = React.useState("");
  const [player, setPlayer] = React.useState([]);
  const GShowHandler = async () => {
    //  console.log("gameeeeeee");
    const odata = await fetch("https://api.sportzcube.com/api/index", {
      method: "GET",
      // body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const alldata = await odata.json();
    setShowing(alldata);
    setGame(alldata?.game);
    setPlayer(alldata?.game_players);
    console.log("gameeeeeeeeeeeeeshowinggggg", alldata);
    console.log("howinggggg", alldata?.game);
    console.log("ggggg", player);

    // console.log("hhhhhhhh", alldata.games);
  };
  // post api
  const [save, setSave] = useState("");
  const getDataHandler = async () => {
    onSelectNextHandler();
    // openHandler();
    console.log(
      "gameeeeeee",
      selectedPlayers?.p1?.id,
      selectedPlayers?.p2?.id,
      selectedPlayers?.p3?.id,
      selectedPlayers?.p4?.id,
      selectedPlayers?.p5?.id,
      selectedPlayers?.p6?.id,
      token
    );
    if (selectedPlayers && selectedPlayers.p6 && !selectedPlayers.p6.id) {
      return 0;
    }
    const ggdata = await fetch("https://api.sportzcube.com/api/choosePlayer", {
      method: "POST",
      body: JSON.stringify({
        player1_id: selectedPlayers?.p1?.id,
        player2_id: selectedPlayers?.p2?.id,
        player3_id: selectedPlayers?.p3?.id,
        player4_id: selectedPlayers?.p4?.id,
        player5_id: selectedPlayers?.p5?.id,
        player6_id: selectedPlayers?.p6?.id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(data);
    const showdata = await ggdata.json();
    getHandler();
    setSave(showdata);
    alert(
      "Congratulations on submitting a complete SportzCube! Come back tomorrow for a new SportzCube"
    );
    console.log("jjjjjjjjjjjjjjjjjjjjjj", showdata);
    // console.log("hhhhhhhh", alldata.games);
  };

  //get post api

  const [dataPlayers, setDataPlayers] = React.useState("");
  const getHandler = async () => {
    //  console.log("gameeeeeee");
    const gamdata = await fetch(
      "https://api.sportzcube.com/api/getUserPlayers",
      {
        method: "GET",
        // body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(data);
    const gdata = await gamdata.json();
    setDataPlayers(gdata);
    // setPlayer(gdata?.userPlayers)
    console.log("iiiiiiiiiiiii", gdata);
    gdata.status &&
      setSelectedPlayers({
        p1: gdata?.user_players[0],
        p2: gdata?.user_players[1],
        p3: gdata?.user_players[2],
        p4: gdata?.user_players[3],
        p5: gdata?.user_players[4],
        p6: gdata?.user_players[5],
      });
  };

  useEffect(() => {
    GShowHandler();
    getHandler();
  }, []);

  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [open, setOpen] = useState(false);
  const conText = () => {
    return (
      <>
        Congratulations on submitting a complete SportzCube! Come back tomorrow
        for a new SportzCube.
      </>
    );
  };
  const openHandler = () => {
    setOpen(true);
  };
  // console.log("mmmmmmmmmm", showing?.game_players[0]?.position?.position);
  return (
    <>
      <CustomModel
        buttonHide={true}
        open={open}
        variant={"submit"}
        setOpen={setOpen}
        // innerText={innerText}
        conText={conText}
      />

      <div
        className="pb-7 min-h-[100vh]"
        style={{
          background:
            "linear-gradient(90deg, rgba(4,14,60,1) 26%, rgba(26,47,123,.98) 75%)",
        }}
      >
        <NavApp />
        {game && (
          <DateTime game={game} setSecondsRemaining={setSecondsRemaining} />
        )}
        <div className="text-white rounded-lg border-2 mt-5 text-lg mx-6 mb-4 p-3 space-between flex items-center ">
          {showing &&
            showing?.game_players &&
            page == 0 &&
            showing?.game_players[0]?.position?.position}
          {showing &&
            showing?.game_players &&
            page == 1 &&
            showing?.game_players[4]?.position?.position}
          {showing &&
            showing?.game_players &&
            page == 2 &&
            showing?.game_players[8]?.position?.position}
          {showing &&
            showing?.game_players &&
            page == 3 &&
            showing?.game_players[12]?.position?.position}
          {showing &&
            showing?.game_players &&
            page == 4 &&
            showing?.game_players[16]?.position?.position}
          {showing &&
            showing?.game_players &&
            page == 5 &&
            showing?.game_players[20]?.position?.position}
          <span className="ml-auto cursor-pointer">
            <span onClick={onSelectPreviousHandler}>
              {" "}
              <ArrowBackIcon />
            </span>
            <span onClick={onSelectNextHandler}>
              <ArrowForwardIcon />
            </span>
          </span>
        </div>
        <div className="flex rows ml-6">
          <Grid container gap={1} className="">
            {player.map(
              (item, index) =>
                page === 0 &&
                index <= 3 && (
                  <Grid xs={5.6} lg={5.8}>
                    {/* {console.log(
                      "pop",
                      item.id,

                      dataPlayers
                    )} */}
                    {dataPlayers?.status == true &&
                    dataPlayers.user_players &&
                    dataPlayers.user_players?.length != 0 &&
                    dataPlayers.user_players[page].player_id == item.id ? (
                      <div
                        className=""
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   handleSelectedPlayers("p1", item);
                        // }}
                      >
                        <Center
                          style={{
                            border: "10px solid ",
                            borderRadius: "50px",
                            background: "#65a765",
                          }}
                          data={item}
                        />
                      </div>
                    ) : (
                      dataPlayers?.status == true && (
                        <div
                          className=""
                          // onClick={() => {
                          //   setSelectedIndex(index);
                          //   handleSelectedPlayers("p1", item);
                          // }}
                        >
                          <Center
                            style={{
                              border: "2px solid white",
                              background: "initial",
                            }}
                            data={item}
                          />
                        </div>
                      )
                    )}
                    {dataPlayers && dataPlayers?.status != true && (
                      <div
                        className=""
                        onClick={() => {
                          setSelectedIndex(index);
                          handleSelectedPlayers("p1", item);
                        }}
                      >
                        <Center
                          style={{
                            border:
                              selectedIndex == index
                                ? "4px solid "
                                : "2px solid white",
                            background:
                              selectedIndex === index ? "#65a765" : "initial",
                          }}
                          data={item}
                        />
                      </div>
                    )}
                  </Grid>
                )
            )}
            {player.map(
              (item, index) =>
                page === 1 &&
                index >= 4 &&
                index <= 7 && (
                  <Grid xs={5.6} lg={5.8}>
                    {console.log(
                      "pop",
                      item.id,

                      dataPlayers
                    )}
                    {dataPlayers?.status == true &&
                    dataPlayers.user_players &&
                    dataPlayers.user_players?.length != 0 &&
                    dataPlayers.user_players[page].player_id == item.id ? (
                      <div
                        className=""
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   handleSelectedPlayers("p1", item);
                        // }}
                      >
                        <Center
                          style={{
                            border: "10px solid ",
                            borderRadius: "50px",
                            background: "#65a765",
                          }}
                          data={item}
                        />
                      </div>
                    ) : (
                      dataPlayers?.status == true && (
                        <div
                          className=""
                          // onClick={() => {
                          //   setSelectedIndex(index);
                          //   handleSelectedPlayers("p1", item);
                          // }}
                        >
                          <Center
                            style={{
                              border: "2px solid white",
                              background: "initial",
                            }}
                            data={item}
                          />
                        </div>
                      )
                    )}
                    {dataPlayers && dataPlayers?.status != true && (
                      <div
                        className=""
                        onClick={() => {
                          setSelectedIndex(index);
                          handleSelectedPlayers("p2", item);
                        }}
                      >
                        <Center
                          style={{
                            border:
                              selectedIndex == index
                                ? "4px solid "
                                : "2px solid white",
                            background:
                              selectedIndex === index ? "#65a765" : "initial",
                          }}
                          data={item}
                        />
                      </div>
                    )}
                  </Grid>
                )
            )}
            {player.map(
              (item, index) =>
                page === 2 &&
                index > 7 &&
                index <= 11 && (
                  <Grid xs={5.6} lg={5.8}>
                    {console.log(
                      "pop",
                      item.id,

                      dataPlayers
                    )}
                    {dataPlayers?.status == true &&
                    dataPlayers.user_players &&
                    dataPlayers.user_players?.length != 0 &&
                    dataPlayers.user_players[page].player_id == item.id ? (
                      <div
                        className=""
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   handleSelectedPlayers("p1", item);
                        // }}
                      >
                        <Center
                          style={{
                            border: "10px solid ",
                            borderRadius: "50px",
                            background: "#65a765",
                          }}
                          data={item}
                        />
                      </div>
                    ) : (
                      dataPlayers?.status == true && (
                        <div
                          className=""
                          // onClick={() => {
                          //   setSelectedIndex(index);
                          //   handleSelectedPlayers("p1", item);
                          // }}
                        >
                          <Center
                            style={{
                              border: "2px solid white",
                              background: "initial",
                            }}
                            data={item}
                          />
                        </div>
                      )
                    )}
                    {dataPlayers && dataPlayers?.status != true && (
                      <div
                        className=""
                        onClick={() => {
                          setSelectedIndex(index);
                          handleSelectedPlayers("p3", item);
                        }}
                      >
                        <Center
                          style={{
                            border:
                              selectedIndex == index
                                ? "4px solid "
                                : "2px solid white",
                            background:
                              selectedIndex === index ? "#65a765" : "initial",
                          }}
                          data={item}
                        />
                      </div>
                    )}
                  </Grid>
                )
            )}
            {player.map(
              (item, index) =>
                page === 3 &&
                index > 11 &&
                index <= 15 && (
                  <Grid xs={5.6} lg={5.8}>
                    {console.log(
                      "pop",
                      item.id,

                      dataPlayers
                    )}
                    {dataPlayers?.status == true &&
                    dataPlayers.user_players &&
                    dataPlayers.user_players?.length != 0 &&
                    dataPlayers.user_players[page].player_id == item.id ? (
                      <div
                        className=""
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   handleSelectedPlayers("p1", item);
                        // }}
                      >
                        <Center
                          style={{
                            border: "10px solid ",
                            borderRadius: "50px",
                            background: "#65a765",
                          }}
                          data={item}
                        />
                      </div>
                    ) : (
                      dataPlayers?.status == true && (
                        <div
                          className=""
                          // onClick={() => {
                          //   setSelectedIndex(index);
                          //   handleSelectedPlayers("p1", item);
                          // }}
                        >
                          <Center
                            style={{
                              border: "2px solid white",
                              background: "initial",
                            }}
                            data={item}
                          />
                        </div>
                      )
                    )}
                    {dataPlayers && dataPlayers?.status != true && (
                      <div
                        className=""
                        onClick={() => {
                          setSelectedIndex(index);
                          handleSelectedPlayers("p4", item);
                        }}
                      >
                        <Center
                          style={{
                            border:
                              selectedIndex == index
                                ? "4px solid "
                                : "2px solid white",
                            background:
                              selectedIndex === index ? "#65a765" : "initial",
                          }}
                          data={item}
                        />
                      </div>
                    )}
                  </Grid>
                )
            )}
            {player.map(
              (item, index) =>
                page === 4 &&
                index > 15 &&
                index <= 19 && (
                  <Grid xs={5.6} lg={5.8}>
                    {console.log(
                      "pop",
                      item.id,

                      dataPlayers
                    )}
                    {dataPlayers?.status == true &&
                    dataPlayers.user_players &&
                    dataPlayers.user_players?.length != 0 &&
                    dataPlayers.user_players[page].player_id == item.id ? (
                      <div
                        className=""
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   handleSelectedPlayers("p1", item);
                        // }}
                      >
                        <Center
                          style={{
                            border: "10px solid ",
                            borderRadius: "50px",
                            background: "#65a765",
                          }}
                          data={item}
                        />
                      </div>
                    ) : (
                      dataPlayers?.status == true && (
                        <div
                          className=""
                          // onClick={() => {
                          //   setSelectedIndex(index);
                          //   handleSelectedPlayers("p1", item);
                          // }}
                        >
                          <Center
                            style={{
                              border: "2px solid white",
                              background: "initial",
                            }}
                            data={item}
                          />
                        </div>
                      )
                    )}
                    {dataPlayers && dataPlayers?.status != true && (
                      <div
                        className=""
                        onClick={() => {
                          setSelectedIndex(index);
                          handleSelectedPlayers("p5", item);
                        }}
                      >
                        <Center
                          style={{
                            border:
                              selectedIndex == index
                                ? "4px solid "
                                : "2px solid white",
                            background:
                              selectedIndex === index ? "#65a765" : "initial",
                          }}
                          data={item}
                        />
                      </div>
                    )}
                  </Grid>
                )
            )}
            {player.map(
              (item, index) =>
                page === 5 &&
                index > 19 &&
                index <= 23 && (
                  <Grid xs={5.6} lg={5.8}>
                    {console.log(
                      "pop",
                      item.id,

                      dataPlayers
                    )}
                    {dataPlayers?.status == true &&
                    dataPlayers.user_players &&
                    dataPlayers.user_players?.length != 0 &&
                    dataPlayers.user_players[page].player_id == item.id ? (
                      <div
                        className=""
                        // onClick={() => {
                        //   setSelectedIndex(index);
                        //   handleSelectedPlayers("p1", item);
                        // }}
                      >
                        <Center
                          style={{
                            border: "10px solid ",
                            borderRadius: "50px",
                            background: "#65a765",
                          }}
                          data={item}
                        />
                      </div>
                    ) : (
                      dataPlayers?.status == true && (
                        <div
                          className=""
                          // onClick={() => {
                          //   setSelectedIndex(index);
                          //   handleSelectedPlayers("p1", item);
                          // }}
                        >
                          <Center
                            style={{
                              border: "2px solid white",
                              background: "initial",
                            }}
                            data={item}
                          />
                        </div>
                      )
                    )}
                    {dataPlayers && dataPlayers?.status != true && (
                      <div
                        className=""
                        onClick={() => {
                          setSelectedIndex(index);
                          handleSelectedPlayers("p6", item);
                        }}
                      >
                        <Center
                          style={{
                            border:
                              selectedIndex == index
                                ? "4px solid "
                                : "2px solid white",
                            background:
                              selectedIndex === index ? "#65a765" : "initial",
                          }}
                          data={item}
                        />
                      </div>
                    )}
                  </Grid>
                )
            )}
          </Grid>
        </div>
        {console.log("secondsRemaining")}
        {console.log(secondsRemaining)}
        <div className="text-center items-center mt-6 ">
          {page < 5 ? (
            <Button
              color="white"
              variant="outlined"
              onClick={onSelectNextHandler}
              style={buttonStyle}
            >
              Select Next
            </Button>
          ) : dataPlayers.status || !(secondsRemaining > 0) ? (
            <Button
              color="white"
              variant="outlined"
              onClick={() => navigate("/leaderboard")} // Define this function to handle viewing the scoreboard
              style={buttonStyle}
            >
              View Scoreboard
            </Button>
          ) : (
            <Button
              color="white"
              variant="outlined"
              onClick={getDataHandler}
              style={buttonStyle}
            >
              Submit Team
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
